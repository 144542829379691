import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Grid from "@mui/material/Grid/Grid";
import React from "react";

interface IProps {
    options: string[];
    onChange: (value: string[]) => void;
    other: boolean
}

const MultipleOptionsSelect: React.FC<IProps> = (props) => {
    const [values, setValues] = React.useState<string[]>([]);
    const [openFieldValue, setOpenFieldValue] = React.useState<string>("");

    const updateValues = (values: string[], openFieldValue: string) => {
        setValues(values);
        setOpenFieldValue(openFieldValue);
        props.onChange([...values, openFieldValue])
    }

    var openField: JSX.Element | null = null;

    if (props.other) {
        openField = <>
            <Grid item xs container>
                <label>Otros</label>
                <input
                    type={"text"}
                    name={"other"}
                    onChange={e => setOpenFieldValue(e.target.value)}
                    onBlur={(e) => { updateValues(values, e.target.value) }}
                />
            </Grid>
        </>
    }

    return <>
        {
            props.options.map(o => (
                <FormControlLabel
                    value={o}
                    control={<Checkbox name={o} onChange={(event) => {
                        var already = values.find(v => v === event.currentTarget.name);
                        let newValues = [];
                        if (event.target.checked && already === undefined)
                            newValues = [...values, o]
                        else
                            newValues = [...values.filter(v => v !== o)]
                        updateValues(newValues, openFieldValue)
                    }}
                    />}
                    label={o}
                    labelPlacement="end"
                />
            ))
        }
        {openField}
    </>

}

export default MultipleOptionsSelect;