import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import React from 'react';
import styles from './Instrucciones.module.scss'

const Instrucciones = () => {

  return (
    <Grid container direction="column" className={styles.root} marginY={'10px'}>
      <Grid item container sm={5} justifyContent="center">
        <Typography variant="h5" gutterBottom>
          <b>Instrucciones</b>
        </Typography>
      </Grid>
      <Grid item container sm={5} justifyContent="center">
        <Typography variant="h6" gutterBottom>
          <b>Selecciona</b> las opciones o <b>escribe</b> donde corresponda para contestar.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Instrucciones;