
import React from 'react';
import { IStepProps } from '../../FormLoader/types';

import Grid from '@mui/material/Grid';

import styles from './WhoDoYouLiveWith.module.scss'
import Button from '../button/Button';
import Question from '../../models/Question';
import validateForm from '../../utils/validateForm';
import Btn from '../Btn/Btn';

//images
import muchas from '../../images/muchas.svg';
import muchos from '../../images/muchos.svg';
import pocas from '../../images/pocas.svg';
import pocos from '../../images/pocos.svg';

import no_amigas from '../../images/no_amigas.svg';
import no_amigos from '../../images/no_amigos.svg';
import solo from '../../images/solo.svg';
import Code from '../Code';
import MultipleSelection from '../../FormLoader/form-components/MultipleSelection/MultipleSelection';
import Confirm from '../../FormLoader/form-components/Confirm/Confirm';
import { CONFIRM } from '../../const';

const Friends = (props: { code: string, label: string, values: string[], onChange: (values: string[]) => void }) => {

  const handleOnChange = (value: string) => {
    if (props.values.indexOf(value) > -1)
      props.onChange(props.values.filter(v => v !== value));
    else
      props.onChange([...props.values, value])
  };

  return (
    <Grid item container className={styles.familySelect}>
      <Grid item container xs={12} alignItems="center">
        <Code>{props.code}</Code>
        <label style={{ fontSize: `2rem` }} htmlFor="name">{props.label}</label>
      </Grid>

      <Grid item xs={12} container spacing={2} >
        <Grid item xs={12} container >

          <Grid item xs={12} container justifyContent="center" >
            <Grid>
              <Btn label="Muchas" value={"1"} onChange={(value) => handleOnChange(value)} image={muchas} />
            </Grid>

            <Grid>
              <Btn label="Muchos" value={"2"} onChange={(value) => handleOnChange(value)} image={muchos} />
            </Grid>

            <Grid>
              <Btn label="Pocas" value={"3"} onChange={(value) => handleOnChange(value)} image={pocas} />
            </Grid>

            <Grid>
              <Btn label="Pocos" value={"4"} onChange={(value) => handleOnChange(value)} image={pocos} />
            </Grid>

          </Grid>

          <Grid item xs={12} container justifyContent="center" >
            <Grid>
              <Btn label="No tengo amigas" value={"5"} onChange={(value) => handleOnChange(value)} image={no_amigas} />
            </Grid>

            <Grid>
              <Btn label="No tengo amigos" value={"5"} onChange={(value) => handleOnChange(value)} image={no_amigos} />
            </Grid>

            <Grid>
              <Btn label="Me siento sola" value={"7"} onChange={(value) => handleOnChange(value)} image={solo} />
            </Grid>

            <Grid>
              <Btn label="Me siento solo" value={"8"} onChange={(value) => handleOnChange(value)} image={solo} />
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </Grid >
  );
}

export interface IFriendsInfo {
  trustPeople: Question<string[]>;
  friends: Question<string>;
};

export const friendsInfoForm: IFriendsInfo = {
  trustPeople: new Question("RA-7", '¿A quién le platicas cuando sientes felicidad, tristeza, enojo o miedo?', []),
  friends: new Question("RA-8", '¿Tienes amigos o amigas?','')
};

const FriendsInfo = ({ onFinish }: IStepProps<IFriendsInfo>) => {

  const [form, setForm] = React.useState<IFriendsInfo>(friendsInfoForm);

  const handleSummit = () => {
    if (validateForm(form))
      onFinish(form);
  };

  const complete = validateForm(form);

  return (
    <Grid container direction="column" className={styles.root}>
      <Grid item container justifyContent="center">
        <div className={styles.form}>

          <MultipleSelection
            code={form.trustPeople.key}
            label={form.trustPeople.label}
            values={form.trustPeople.value}
            onChange={(values: string[]) => setForm({ ...form, trustPeople: form.trustPeople.setValue(values) })}
            openField
          />

          <Grid item container>
            <Grid item xs={11} container>
              <Grid item xs={12}>
                <Code>{form.friends.key}</Code>
                <label >{form.friends.label}</label>
              </Grid>
              <Grid item xs={12}>
                <Confirm onChange={s => {
                  if (s)
                    form.friends.setValue(CONFIRM.YES)
                  else
                    form.friends.setValue(CONFIRM.NO)
                }} />
              </Grid>
            </Grid>
          </Grid>

        </div>
      </Grid>
      <Grid item container justifyContent="center">
        <Button disabled={!complete} onClick={handleSummit}>Continuar</Button>
      </Grid>
    </Grid>
  )
};

export default FriendsInfo;