import React from "react";
interface IProps {

}
const Code = (props: { children: React.ReactNode }) => {
    return (
        <span style={{
            fontSize: `.8rem`,
            fontStyle: `italic`,
            margin: `0 5px`
        }}>
            {props.children}.-
        </span>
    )
}

export default Code;