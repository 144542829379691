export enum CONFIRM {
    YES = 'SI',
    NO = 'NO',
}

export enum LEVELS {
    ALWAYS = 'siempre',
    MANY_TIMES = 'muchas veces',
    SOME_TIMES = 'pocas veces',
    NEVER = 'ni una vez'
}

export enum SEX {
  MEN = "Hombre",
  WOMAN = "Mujer"
}