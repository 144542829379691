
import { IPersonalData } from "../components/steps/PersonalData";
import { IForm } from "../components/Forms/Instrumento";

function validateForm(form: Partial<IForm>) {
    let k: keyof Partial<IForm>;
    for (k in form) {
        if (Object.prototype.hasOwnProperty.call(form, k)) {
            const element = form[k];
            if (element && !element.validate())
                return false;
        }
    }
    return true;
}

export default validateForm;