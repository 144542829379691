
import './App.scss';


import Intro from './components/steps/Intro';
import WhoDoYouLiveWith, { } from './components/steps/WhoDoYouLiveWith';
import PersonalData, { } from './components/steps/PersonalData';
import FriendsInfo, { } from './components/steps/FriendsInfo';
import Final, { } from './components/steps/Final';
import EmotionalInfo, { } from './components/steps/EmotionalInfo';
import DeathAwareness, { } from './components/steps/DeathAwareness';
import SelfAwareness, { } from './components/steps/SelfAwareness';
import Container from '@mui/material/Container';

interface IProps { }

function All(props: IProps) {

    return (
        <Container maxWidth="md" style={{ marginTop: 25, marginBottom: 25 }}  >
            <Intro onFinish={() => { }} />
            <PersonalData onFinish={() => { }} />
            <WhoDoYouLiveWith onFinish={() => { }} />
            <FriendsInfo onFinish={() => { }} />
            <EmotionalInfo onFinish={() => { }} />
            <DeathAwareness onFinish={() => { }} />
            <SelfAwareness onFinish={() => { }} />
            <Final onFinish={() => { }} />
        </Container>
    );
}

export default All;
