

const stringJsxTemplate = (
    str: string,
    formatingFunction?: (value: number | string | JSX.Element) => React.ReactNode,
    ...values: Array<number | string | JSX.Element>
) => {
    const templateSplit = new RegExp(/{(\d)}/g);
    const isNumber = new RegExp(/^\d+$/);
    const splitedText = str.split(templateSplit);
    return splitedText.map(sentence => {
        if (isNumber.test(sentence)) {
            const value = values[Number(sentence)];
            return Boolean(formatingFunction) && formatingFunction !== undefined ? formatingFunction(value) : value;
        }
        return sentence;
    });
};

export default stringJsxTemplate;