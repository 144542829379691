import React from 'react';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Footer = () => (
  <footer>
    <Grid container direction='column' alignItems='center'>
      <Typography variant="h5" component="h3">
        CIFAVA
      </Typography>
      <Typography component="p">
        @2024 Isabel AC Ayuda para la Vida, Se vale pedir ayuda
      </Typography>
      <Typography component="p">
        <Link href="/aviso-de-privacidad" target="_blank" onClick={() => { }}>
          Aviso de Privacidad
        </Link>
      </Typography>
    </Grid>
  </footer>
)

export default Footer;