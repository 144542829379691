import { FormConfig } from "../../FormLoader/types";

// Agregar
// Nombre
// Edad
// Genero
// Lugar de trabajo y puesto

export const formConfig: FormConfig = {
    type: "stepper",
    config: {
        onFinish: {
            save: true,
            confirmation_layout: {
                type: 'confirmation',
                value: {}
            },
            restart: true
        }
    },
    value: [
        // {
        //     content: [
        //         {
        //             type: 'page',
        //             value:
        //             {
        //                 content: [
        //                     {
        //                         type: 'input', // compose-input
        //                         value: {
        //                             type: "text",
        //                             code: "RA-2",
        //                             label: "¿Quién te cuida?",
        //                             icon: "SVG/triste.svg"
        //                         }
        //                     },
        //                 ]
        //             }
        //         }
        //     ],
        // },
        {
            // title: "test",
            content: [
                {
                    type: 'intro',
                    value: {}
                },
            ]
        },
        {
            content: [
                {
                    type: 'page',
                    value:
                    {
                        content: [
                            {
                                type: 'input',
                                value: {
                                    type: "short-text",
                                    code: "FOLIO",
                                    hide_code: true,
                                    label: "Folio"
                                }
                            },
                            {
                                type: 'select-gender',
                                value: {
                                    code: "GENERO",
                                    hide_code: true,
                                    label: "¿Yo soy?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "NOMBRE",
                                    hide_code: true,
                                    label: "¿Me llamo?"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "template",
                                    code: "EDAD",
                                    hide_code: true,
                                    template: "Tengo: {0} años",
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "ESCUELA",
                                    hide_code: true,
                                    label: "Nombre de escuela"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "template",
                                    code: "GRADO",
                                    hide_code: true,
                                    template: "Grado: {0}"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "template",
                                    code: "GRUPO",
                                    hide_code: true,
                                    template: "Grupo: {0}"
                                }
                            },
                        ]
                    }
                },

            ]
        },
        {
            content: [
                {
                    type: 'page',
                    value:
                    {
                        content: [
                            {
                                type: 'instrucciones', 
                            },
                            {
                                type: 'select-family', // compose-input
                                value: {
                                    code: "EF-1",
                                    label: "¿Con quíen vives?",
                                    icon: "SVG/te-cuida.svg",
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "EF-1-OTROS",
                                    label: "Otros",
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "RA-2",
                                    label: "¿Quién te cuida?",
                                    icon: "SVG/te-cuida.svg"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "multiple-options",
                                    code: "RA-3",
                                    options: ['Familia', 'Amistades'],
                                    other: true,
                                    label: "¿A quién le platicas cuando sientes felicidad, tristeza, enojo o miedo?",
                                    icon: "SVG/hablar.svg"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "RA-4",
                                    label: "¿Tienes amigos o amigas?",
                                    icon: "SVG/amigosv2.svg"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "A-5",
                                    label: "¿Quién te quiere mucho?",
                                    icon: "SVG/te-quiere.svg"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "A-6",
                                    label: "¿Cómo te demuestra que te quiere la persona que te cuida?",
                                    icon: "SVG/te-cuidan.svg"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "A-7",
                                    label: "¿Tú, a quién quieres mucho?",
                                    icon: "SVG/te-quieres.svg"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "A-8",
                                    label: "¿Cómo le demuestras a esa persona que la quieres?",
                                    icon: "SVG/querer.svg"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "E-9",
                                    label: "¿Qué haces cuando te sientes triste?",
                                    icon: "sad.svg",
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "E-10",
                                    label: "¿Qué haces cuando te sientes enojado o enojada?",
                                    icon: "SVG/personas-malas.svg"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "E-11",
                                    label: "¿Qué haces cuando sientes miedo?",
                                    icon: "SVG/miedo.svg"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "E-12",
                                    label: "¿Qué haces cuando te sientes preocupado o preocupada?",
                                    icon: "SVG/preocupado.svg"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "E-13",
                                    label: "¿Qué haces cuando te sientes feliz?",
                                    icon: "SVG/yo.svg"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "multiple-options",
                                    code: "FRP-14",
                                    options: ["Si me gusta", "No me gusta", "Si me gusta, pero no puedo dormir"],
                                    label: "¿Te gusta dormir?",
                                    icon: "SVG/dormir.svg",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-14-A",
                                                label: "¿Por qué?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-15",
                                    label: "¿Te gusta ir a la escuela?",
                                    icon: "SVG/escuela.svg",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-15-A",
                                                label: "¿Por qué?"
                                            }
                                        },
                                    ]
                                }
                            },

                        ]
                    }
                },

            ]
        },
        {
            content: [
                {
                    type: 'page',
                    value:
                    {
                        content: [
                            {
                                type: 'death-select', // compose-input
                                value: {
                                    code: "CMU-16",
                                    label: "Selecciona ¿Quiénes pueden morir?",
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "CMI-17",
                                    icon: "SVG/tumba.svg",
                                    label: "¿Después de que alguien muere, puede revivir?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    icon: "SVG/calaca.svg",
                                    code: "CMSFV-18",
                                    label: "Después de que muere una persona o un animal, ¿el cuerpo deja de funcionar?"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "CM-19",
                                    label: "¿Qué pasa cuando una persona se muere?",
                                    icon: "SVG/muerto.svg"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "CMCF-20",
                                    label: "¿Por qué puede morir una persona o animal?",
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-21",
                                    label: "¿Alguna vez has sentido que quieres dormir y no despertar nunca?",
                                    icon: "SVG/triste_1.svg",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-21-A",
                                                label: "¿Cuándo?"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-22",
                                    label: "¿Alguna vez has sentido que tienes muchas ganas de llorar?",
                                    icon: "SVG/llorar.svg",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-22-A",
                                                label: "¿Cuándo?"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "E-23",
                                    label: "¿Es bueno llorar?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "E-23-A",
                                                label: "¿Por qué?"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-24",
                                    label: "¿Alguna vez has sentido que te quieres hacer daño?",
                                    icon: "SVG/corazon-roto.svg",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-24-A",
                                                label: "¿Cuándo?"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-25",
                                    label: "¿Alguna vez has probado bebidas alcohólicas?",
                                    icon: "SVG/fumar.svg",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-25-A",
                                                label: "¿Porqué?"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-26",
                                    label: "¿Alguna vez has fumado?",
                                    icon: "SVG/fumar_v2.svg",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-26-A",
                                                label: "¿Porqué?"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-27",
                                    label: "¿Te gusta la ropa que usas?",
                                    icon: "SVG/lentes-cool.svg",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-27-A",
                                                label: "¿Por qué?"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "FRP-28",
                                    label: "¿En qué te gustaría trabajar cuándo seas grande?",
                                    icon: "SVG/corazon.svg"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "FRP-29",
                                    label: "¿Qué es lo que más te hace reír?",
                                    icon: "SVG/feliz.svg"
                                }
                            },
                        ]
                    }
                },
            ]
        },
    ]
}