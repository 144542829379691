
import React from 'react';
import { IStepProps } from '../../FormLoader/types';

import Grid from '@mui/material/Grid';

import styles from './WhoDoYouLiveWith.module.scss'
import Button from '../button/Button';
import Question from '../../models/Question';
import validateForm from '../../utils/validateForm';
import corazon_bit from '../../images/SVG/corazon-bit.svg';
import comida from '../../images/SVG/comida.svg';
import temblores from '../../images/SVG/temblores.svg';
import mano_mojada from '../../images/SVG/mano-mojada.svg';
import triste from '../../images/SVG/triste.svg';
import corazon_roto from '../../images/SVG/corazon-roto.svg';
import si from '../../images/SVG/si.svg';
import bad from '../../images/SVG/bad.svg';
import foco from '../../images/SVG/foco.svg';
import ojo_corazon from '../../images/SVG/ojos-corazon.svg';

import Code from '../Code';
import { CONFIRM } from '../../const';
import Confirm from '../../FormLoader/form-components/Confirm/Confirm';

export interface ISelfAwareness {
  your_heart_beats_fast: Question<string | null>;
  your_heart_beats_fast_when: Question<string>;

  stomach_pain: Question<string | null>;
  stomach_pain_when: Question<string>;

  your_legs_are_shake: Question<string | null>;
  your_legs_are_shake_when: Question<string>;

  your_hands_sweat: Question<string | null>;
  your_hands_sweat_when: Question<string>;

  never_wake_up: Question<string | null>;
  never_wake_up_when: Question<string>;

  cry_feelings: Question<string | null>;
  cry_feelings_when: Question<string>;

  hurt_feelings: Question<string | null>;
  hurt_feelings_when: Question<string>;

  do_everything_right: Question<string | null>;
  do_everything_right_when: Question<string>;

  unfairly_punished: Question<string | null>;
  unfairly_punished_when: Question<string>;

  youre_smart: Question<string | null>;
  youre_smart_when: Question<string>;

  you_are_pretty: Question<string | null>;
  you_are_pretty_when: Question<string>;
};

export const selfAwarenessForm: ISelfAwareness = {
  your_heart_beats_fast: new Question("AN-21", '¿Alguna vez has sentido que tu corazón late muy rápido?', null),
  your_heart_beats_fast_when: new Question("AN-21-A", "¿Cuándo?", "", true),
  stomach_pain: new Question("AN-22", '¿Alguna vez has sentido que te duele tu estómago?', null),
  stomach_pain_when: new Question("AN-22-A", "¿Cuándo?", "", true),
  your_legs_are_shake: new Question("AN-23", '¿Alguna vez has sentido que te tiemblan las piernas?', null),
  your_legs_are_shake_when: new Question("AN-23-A", "¿Cuándo?", "", true),
  your_hands_sweat: new Question("AN-24", '¿Alguna vez has sentido que te sudan las manos?', null),
  your_hands_sweat_when: new Question("AN-24-A", "¿Cuándo?", "", true),
  never_wake_up: new Question("D-25", '- ¿Alguna vez has sentido que quieres dormir y no despertar nunca?', null),
  never_wake_up_when: new Question("D-25-A", "¿Cuándo?", "", true),
  cry_feelings: new Question("D-26", '¿Alguna vez has sentido que tienes muchas ganas de llorar?', null),
  cry_feelings_when: new Question("D-26-A", "¿Cuándo?", "", true),
  hurt_feelings: new Question("D-27", '¿Alguna vez has sentido que te quieres hacer daño?', null),
  hurt_feelings_when: new Question("D-27-A", "¿Cuándo?", "", true),
  do_everything_right: new Question("AU-28", '¿Alguna vez has pensado que todo lo haces bien?', null),
  do_everything_right_when: new Question("AU-28-A", "¿Cuándo?", "", true),
  unfairly_punished: new Question("AN-29", '¿Alguna vez te han castigado por cosas que tu no hiciste?', null),
  unfairly_punished_when: new Question("AN-29-A", "¿Cuándo?", "", true),
  youre_smart: new Question("AU-30", '- ¿Alguna vez te has dado cuenta de que eres muy inteligente?', null),
  youre_smart_when: new Question("AU-30-A", "¿Cuándo?", "", true),
  you_are_pretty: new Question("AU-31-A", '¿Alguna vez te has dado cuenta de que eres muy bonita/guapo?', null),
  you_are_pretty_when: new Question("AU-31", "¿Cuándo?", "", true),
};

const questionGenerator = (
  form: ISelfAwareness,
  setForm: React.Dispatch<React.SetStateAction<ISelfAwareness>>
) => (label: (keyof ISelfAwareness), icon: string) => {

  const additionalQuestionLabel = `${label}_when` as keyof ISelfAwareness;

  return (
    <Grid item xs={12}>

      <Grid item xs={6}>
        <img src={icon} alt="" height="auto" width="100%" />
      </Grid>

      <Grid item xs={11}>
        <Grid item xs={12}>
          <Code>{form[label].key}</Code>
          <label >{form[label].label}</label>
        </Grid>
        <Grid item xs={12}>
          <Confirm onChange={s => {
            if (s)
              form[label].setValue(CONFIRM.YES)
            else
              form[label].setValue(CONFIRM.NO)
          }} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {/* { @ts-ignore } */}
        <Code>{form[additionalQuestionLabel]!.key}</Code>
        {/* @ts-ignore */}
        <label htmlFor={additionalQuestionLabel}>{form[additionalQuestionLabel]!.label}</label>
        <input type="text" name={label}
          // @ts-ignore  
          onChange={e => setForm({ ...form, [additionalQuestionLabel]: form[additionalQuestionLabel].setValue(e.target.value) })} />
      </Grid>

    </Grid>
  )
}

const SelfAwareness = ({ onFinish }: IStepProps<ISelfAwareness>) => {

  const [form, setForm] = React.useState<ISelfAwareness>(selfAwarenessForm);

  const handleSummit = () => {
    if (validateForm(form))
      onFinish(form);
  };

  const complete = validateForm(form);

  const getQuestion = questionGenerator(form, setForm)
  return (
    <Grid container direction="column" className={styles.root}>

      <Grid item container spacing={10}>
        {getQuestion(`your_heart_beats_fast`, corazon_bit)}
        {getQuestion(`stomach_pain`, comida)}
        {getQuestion(`your_legs_are_shake`, temblores)}
        {getQuestion(`your_hands_sweat`, mano_mojada)}
        {getQuestion(`never_wake_up`, triste)}
        {getQuestion(`cry_feelings`, triste)}
        {getQuestion(`hurt_feelings`, corazon_roto)}
        {getQuestion(`do_everything_right`, si)}
        {getQuestion(`unfairly_punished`, bad)}
        {getQuestion(`youre_smart`, foco)}
        {getQuestion(`you_are_pretty`, ojo_corazon)}
      </Grid>

      <Grid item container justifyContent="center">
        <Button disabled={!complete} onClick={handleSummit}>Continuar</Button>
      </Grid>
    </Grid>
  )
};

export default SelfAwareness;