import React from 'react';
import styles from './Home.module.scss';

import { Container, Grid } from '@mui/material';

const Home = () => {
    const [code, setCode] = React.useState<string>('');

    var onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCode(event.target.value)
    }

    var onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        window.location.href = `${window.location.origin}/${code}`;
    }

    return (
        <Container maxWidth="sm">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <label htmlFor='codigo'>Código:</label>
                </Grid>
                <Grid item xs={10}>
                    <input
                        type="text"
                        name="codigo"
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={2}>
                    <button
                        className={styles.btn}
                        onClick={onClick}>
                        Ir
                    </button>
                </Grid>


            </Grid>
        </Container>
    );
};

export default Home;