import React from 'react';

import { FormConfig } from '../types';

import { Typography } from "@mui/material";

import { getComponents } from '../LayoutProcesor';
import FormProvider from '../../formDataStore/formProvider';

import { formConfig } from '../../pages/forms/InstrumentoConfig';
import { formConfig as formConfigAdultos } from '../../pages/forms/InstrumentoAduntosConfig';
import { formConfig as formConfigAdolecentes } from '../../pages/forms/InstrumentoAdolecentesConfig';

const getUrl = (codigo: string) => `https://pjgvzztrpl.execute-api.us-west-2.amazonaws.com/dev/form/${codigo}`

const getFormConfig = (type: string) => {
    console.log(type)
    switch (type) {
        case 'instrumento-adultos':
            return formConfigAdultos;
        case 'instrumento-ninos':
            return formConfig;
        case 'instrumento-adolecentes':
            return formConfigAdolecentes;
        default:
            return formConfig
    }
}

type State =
    | { status: 'empty' }
    | { status: 'loading' }
    | { status: 'success', data: FormConfig }
    | { status: 'error', error: string };

type Action =
    | { type: 'request', }
    | { type: 'success', payload: FormConfig }
    | { type: 'failure', error: string };

const formFetchReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'request':
            return {
                ...state,
                status: 'loading'
            };
        case 'success':
            return {
                ...state,
                status: 'success',
                data: action.payload,
            };
        case 'failure':
            return {
                ...state,
                status: 'error',
                error: action.error,
            };
        default:
            throw new Error();
    }
};


const useFormLoader = (codigo: string | undefined): React.ReactElement | null => {

    const [state, dispatch] = React.useReducer(formFetchReducer, { status: 'empty' });

    React.useEffect(() => {
        let ignore = false;
        var fetchForm = () => {
            if (!codigo) return;
            dispatch({ type: 'request' });
            fetch(getUrl(codigo))
                .then(r => r.json())
                .then(body => {
                    if (!ignore)
                        dispatch({ type: 'success', payload: getFormConfig(body.tipo) })
                })
                .catch(e => { dispatch({ type: 'failure', error: e['error'] }) })
        }

        fetchForm();

        return () => {
            ignore = true; // Handle Unmount, stop Settings Fetched Data
        }
    }, [codigo]);


    if (state.status === 'empty') return null;

    if (state.status === 'loading') return <></>;

    if (state.status === 'error') return (
        <div style={{ height: "100%", width: "100%", margin: "auto", textAlign: "center" }}>
            <Typography variant="h5" component="h3">
                {state.error}
            </Typography>
        </div>
    );

    if (state.status === 'success')
        if (codigo !== undefined)
            return <FormProvider formCode={codigo}>{getComponents(state.data, { code: codigo })}</FormProvider>

    return null;
}


export default useFormLoader;