import React from 'react';
import { Grid } from '@mui/material';


import styles from './MultipleSelection.module.scss';

//images
import familia from '../../../images/familia.svg';
import amigos from '../../../images/amigos.svg';
import { Code } from '@mui/icons-material';
import Btn from '../../../components/Btn/Btn';

var OPTIONS = [
  {
    value: `Familia`,
    image: familia
  },
  {
    value: `Amistadades`,
    image: amigos
  }
];


type Props = {
  code: string,
  label: string,
  values: string[],
  options?: { value: string, image: string }[],
  onChange: (values: string[]) => void,
  openField: boolean
}

const MultipleSelection = (props: Props) => {

  const handleOnChange = (value: string) => {
    if (props.values.indexOf(value) > -1)
      props.onChange(props.values.filter(v => v !== value));
    else
      props.onChange([...props.values, value])
  };

  var getOpenField = () => {
    return (
      <>
        <label htmlFor={`otros`}>Otros</label>
        <input
          type="text"
          name={`otros`}
          onChange={e => handleOnChange(e.target.value)}
        />
      </>
    )
  };

  var options = props.options || OPTIONS;

  return (
    <Grid item container className={styles.familySelect}>

      <Grid item container xs={12} alignItems="center">
        <Code>{props.code}</Code>
        <label style={{ fontSize: `1.5rem` }} htmlFor="name">{props.label}</label>
      </Grid>


      <Grid item xs={12} container justifyContent="center" >
        {
          options.map((option) => {
            return (
              <Grid>
                <Btn
                  value={option.value} image={option.image}
                  onChange={(value) => handleOnChange(value)} />
              </Grid>
            )
          })
        }
      </Grid>

      {props.openField ? getOpenField() : null}

    </Grid>
  );
}

export default MultipleSelection;