import { FormConfig } from "../../FormLoader/types";

// Agregar
// Nombre
// Edad
// Genero
// Lugar de trabajo y puesto


export const formConfig: FormConfig = {
    type: "stepper",
    config: {
        onFinish: {
            save: true,
            confirmation_layout: {
                type: 'confirmation',
                value: {}
            },
            restart: true
        }
    },
    value: [
        {
            // title: "test",
            content: [
                {
                    type: 'intro',
                    value: {}
                },
            ]
        },
        {
            content: [
                {
                    type: 'page',
                    value:
                    {
                        content: [
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "EF1",
                                    label: "¿Con quién vives?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "RA2",
                                    label: "¿Quién cuida de ti?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "multiple-options",
                                    options: ['Amigos', 'Familiares'],
                                    other: true,
                                    code: "RA3",
                                    label: "¿A quién le platicas tus emociones? (Tristeza, enojo, felicidad, miedo, preocupación)"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['Si', 'No'],
                                    code: "RA4",
                                    label: "¿Tienes amistades?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "A5",
                                    label: "¿Quién te quiere?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "A6",
                                    label: "¿Cómo te demuestra que te quiere?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "A7",
                                    label: "¿Tú a quién quieres?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "A8",
                                    label: "¿Cómo le/les demuestras que los quieres?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "E9",
                                    label: "¿Qué haces cuando te sientes triste?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "E10",
                                    label: "¿Qué haces cuando te enojas?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "E11",
                                    label: "¿Qué haces cuando sientes miedo?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "E12",
                                    label: "¿Qué haces cuando te preocupas?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "E13",
                                    label: "¿Qué haces cuando estas feliz?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['Si', 'No', 'Si me gusta pero no puedo dormir'],
                                    code: "ERP14",
                                    label: "¿Te gusta dormir?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "ERP14A",
                                                label: "¿Por qué?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['Si', 'No'],
                                    code: "FRP15",
                                    label: "¿Te gusta ir a tu trabajo?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP15A",
                                                label: "¿Por qué?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "CM16",
                                    label: "¿Qué pasa cuando una persona muere?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "CMCF17",
                                    label: "¿Por que puede morir una persona o animal?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['Si', 'No'],
                                    code: "FRP18",
                                    label: "¿Alguna vez has sentido que quieres dormir y no despertar nunca?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP18A",
                                                label: "¿Cuándo?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "FRP19",
                                    label: "¿Cuándo has sentido muchas ganas de llorar?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['Si', 'No'],
                                    code: "E20",
                                    label: "¿Es bueno llorar?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "E20A",
                                                label: "¿Por qué?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['Si', 'No'],
                                    code: "FRP21",
                                    label: "¿Alguna vez has tenido la intensión de hacerte daño?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP21A",
                                                label: "¿Cuándo?"
                                            }
                                        },
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP21B",
                                                label: "¿Cómo?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['Si', 'No'],
                                    code: "FRP22",
                                    label: "¿Consumes bebidas alcohólicas?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP22A",
                                                label: "¿Cuándo?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['Si', 'No'],
                                    code: "FRP23",
                                    label: "¿Fumas?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP23A",
                                                label: "¿Cuándo?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP24",
                                    label: "¿Necesitas consumir algo para aguantar tu jornada laboral?"
                                }
                            },
                            // {
                            //     type: 'group',
                            //     value: {
                            //         code: "FRP25",
                            //         label: "¿Cuáles son tus metas?",
                            //         content: [
                            //             {
                            //                 type: 'input',
                            //                 value: {
                            //                     type: "text",
                            //                     code: "FRP25A",
                            //                     label: "a corto plazo"
                            //                 }
                            //             },
                            //             {
                            //                 type: 'input',
                            //                 value: {
                            //                     type: "text",
                            //                     code: "FRP25B",
                            //                     label: "a mediano plazo"
                            //                 }
                            //             },
                            //             {
                            //                 type: 'input',
                            //                 value: {
                            //                     type: "text",
                            //                     code: "FRP25C",
                            //                     label: "a largo plazo"
                            //                 }
                            //             },
                            //         ]
                            //     }
                            // },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "FRP25",
                                    label: " ¿Cuáles son tus metas a corto, mediano y largo plazo?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "FRP26",
                                    label: "¿Qué haces en tu tiempo libre?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "FRP27",
                                    label: "¿Qué es lo que más te hace reír?"
                                }
                            },
                        ]
                    }
                },

            ]
        }

    ]
}