
import React from 'react';
import { IStepProps } from '../../FormLoader/types';
import fumar from '../../images/SVG/fumar.svg';
import lentes from '../../images/SVG/lentes-cool.svg';
import corazon from '../../images/SVG/corazon.svg';
import feliz from '../../images/SVG/feliz.svg';
import Grid from '@mui/material/Grid';

import styles from './WhoDoYouLiveWith.module.scss'
import Button from '../button/Button';
import Question from '../../models/Question';
import validateForm from '../../utils/validateForm';
import { CONFIRM } from '../../const';
import Checkbox from '../Checkbox/Checkbox';
import Code from '../Code';

const YesNoQuestion = (props: {
  question: Question<CONFIRM | null>,
  questionInfo: Question<string>,
  icon: string,
  onQuestionChange: (main: Question<CONFIRM | null>) => void;
  onQuestionInfoChange: (main: Question<string>) => void;
}) => {

  return (
    <Grid item container >
      <Grid item xs={1} style={{ justifyContent: 'center', display: "flex" }}>
        <img src={props.icon} alt="" height="auto" width="80%" />
      </Grid>
      <Grid item xs={11} container spacing={2}>
        <Grid item container>
          <Code>{props.question.key}</Code>
          <label htmlFor={props.question.label}>{props.question.label}</label>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item xs={12} sm={4} container onClick={e => props.onQuestionChange(props.question.setValue(CONFIRM.YES))}>
            <Grid item container className={styles.selectButton} spacing={1}>
              <Grid item container justifyContent="center">
                <div>
                  <img src={""} alt="" height="auto" width="100%" />
                </div>
              </Grid>
              <Grid item container justifyContent="center">
                <Checkbox active={props.question.value === CONFIRM.YES} /><span style={{ fontSize: '1rem', fontWeight: 'bold', margin: ' 0px 5px' }}>{CONFIRM.YES}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} container onClick={e => props.onQuestionChange(props.question.setValue(CONFIRM.NO))}>
            <Grid item container className={styles.selectButton} spacing={1}>
              <Grid item container justifyContent="center">
                <div>
                  <img src={""} alt="" height="auto" width="100%" />
                </div>
              </Grid>
              <Grid item container justifyContent="center">
                <Checkbox active={props.question.value === CONFIRM.NO} /><span style={{ fontSize: '.9rem', fontWeight: 'bold', margin: ' 0px 5px' }}>{CONFIRM.NO}</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
        <Code>{props.questionInfo.key}</Code>
          <label htmlFor={props.questionInfo.label}>{props.questionInfo.label}</label>
          <input type="text" name={props.questionInfo.label}
            onChange={e => props.onQuestionInfoChange(props.questionInfo.setValue(e.target.value))} />
        </Grid>

      </Grid>
    </Grid >
  );
}


export interface IFinal {
  use_of_drugs: Question<CONFIRM | null>;
  use_of_drugs_which: Question<string>;
  like_your_clothes: Question<CONFIRM | null>;
  like_your_clothes_why: Question<string>;
  work_expectation: Question<string>;
  what_makes_you_laugh: Question<string>;
};

export const final: IFinal = {
  use_of_drugs: new Question("C-32", '¿Alguna vez has probado bebidas alcohólicas o cigarros?', null),
  use_of_drugs_which: new Question("C-32-A", "¿Cuáles?", "", false),
  like_your_clothes: new Question("AU-33", '¿Te gusta la ropa que usas?', null),
  like_your_clothes_why: new Question("AU-33-A", "¿Por qué? ", "", false),
  work_expectation: new Question("AU-34", "¿En qué te gustaría trabajar cuándo seas grande?", "", false),
  what_makes_you_laugh: new Question("FA-35", "¿Qué es lo que más te hace reír?", "", false),
};

const Final = ({ onFinish }: IStepProps<IFinal>) => {

  const [form, setForm] = React.useState<IFinal>(final);

  const handleSummit = () => {
    if (validateForm(form))
      onFinish(form);
  };

  const complete = validateForm(form);

  return (
    <Grid container direction="column" className={styles.root}>
      <Grid item container justifyContent="center" spacing={8}>

        <YesNoQuestion
          question={form.use_of_drugs}
          questionInfo={form.use_of_drugs_which}
          icon={fumar}
          onQuestionChange={e => setForm({ ...form, use_of_drugs: e })}
          onQuestionInfoChange={e => setForm({ ...form, use_of_drugs_which: e })}
        />

        <YesNoQuestion
          question={form.like_your_clothes}
          questionInfo={form.like_your_clothes_why}
          icon={lentes}
          onQuestionChange={e => setForm({ ...form, like_your_clothes: e })}
          onQuestionInfoChange={e => setForm({ ...form, like_your_clothes_why: e })}
        />

        <Grid item container style={{ justifyContent: 'center' }}>
          <Grid item xs={1}>
            <img src={corazon} alt="" height="80%" width="100%" />
          </Grid>
          <Grid item xs={11}>
            <Code>{form.work_expectation.key}</Code>
            <label htmlFor={form.work_expectation.key}>{form.work_expectation.label}</label>
            <textarea name={form.work_expectation.key}
              onChange={e => setForm({ ...form, work_expectation: form.work_expectation.setValue(e.target.value) })} ></textarea>
          </Grid>
        </Grid>

        <Grid item container style={{ justifyContent: 'center' }}>
          <Grid item xs={1}>
            <img src={feliz} alt="" height="70%" width="100%" />
          </Grid>
          <Grid item xs={11}>
            <Code>{form.what_makes_you_laugh.key}</Code>
            <label htmlFor={form.what_makes_you_laugh.key}>{form.what_makes_you_laugh.label}</label>
            <textarea name={form.what_makes_you_laugh.key}
              onChange={e => setForm({ ...form, what_makes_you_laugh: form.what_makes_you_laugh.setValue(e.target.value) })} ></textarea>
          </Grid>
        </Grid>

      </Grid>
      <Grid item container justifyContent="center">
        <Button disabled={!complete} onClick={handleSummit}>Continuar</Button>
      </Grid>
    </Grid>
  )
};

export default Final;