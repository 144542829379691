import React from 'react';
import styles from './Checkbox.module.scss'

const Checkbox = ({ active }: { active: boolean }) => {
  return (
    <div className={`${styles.checkbox} ${(active) ? styles.checked : ""}`}>
    </div>
  );
}

export default Checkbox;