import { Container } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { getComponents } from '../../LayoutProcesor';
import { FormConfig } from '../../types';

type IProps = {
  children: React.ReactNode
}

const Layout: React.FC<IProps> = (props) => {

  return (
    <Container maxWidth="md">
      <Grid container spacing={4} paddingY={4} alignContent={"center"}>
        {props.children}
      </Grid>
    </Container>
  )
}


export type TPage = {
  type: "page",
  value: { content: FormConfig }
};

const Page: React.FC<TPage> = (props) => {
  const { value: { content } } = props;

  return (
    <Layout>
      {getComponents(content)}
    </Layout>
  );
}

export default Page;