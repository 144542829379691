import React from 'react';
import Grid from '@mui/material/Grid';
import styles from './Confirmation.module.scss'

export type TConfirmation = {
  type: "confirmation",
  value: {}
}

const Confirmation = (props: TConfirmation) => {

  // const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Grid container direction="column" className={styles.root} justifyContent="center">
      <Grid item container md={12} alignItems="center" justifyContent="center">
        <h1>
          Gracias por tu <b>apoyo</b>
        </h1>

      </Grid>
      {/* <Grid item container justifyContent="center">
        <Button onClick={onFinish} >Continuar</Button>
      </Grid> */}
    </Grid>
  )
}

export default Confirmation;