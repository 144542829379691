
import React from 'react';
import { IStepProps } from '../../FormLoader/types';
import IconButton from '../IconButton/IconButton';
import girlImage from '../../images/girl.svg';
import boyImage from '../../images/boy.svg';
import Grid from '@mui/material/Grid';

import styles from './PersonalData.module.scss'
import Button from '../button/Button';
import Question from '../../models/Question';
import validateForm from '../../utils/validateForm';

import { SEX } from '../../const';

export const GenderSelect = (props: { onChange: (sex: SEX) => void, label: string, value: SEX | null }) => {

  return (
    <Grid item container className={styles.genderSelect}>
      <Grid item container md={3} alignItems="center">
        <label>{props.label}</label>
      </Grid>
      <Grid item md={9} container spacing={2} className={styles.input}>
        <Grid item xs={12} md={6}>

          <IconButton
            active={props.value === SEX.WOMAN}
            onClick={() => props.onChange(SEX.WOMAN)}
          >
            <img src={girlImage} alt="" height="100%" width="100%" />
          </IconButton>
        </Grid>
        <Grid item xs={12} md={6}>
          <IconButton
            active={props.value === SEX.MEN}
            onClick={() => props.onChange(SEX.MEN)}
          >
            <img src={boyImage} alt="" height="100%" width="100%" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid >
  );
}

export interface IPersonalData {
  FOLIO: Question<string | null>;
  GENERO: Question<SEX | null>;
  NOMBRE: Question<string>;
  EDAD: Question<number | null>;
  ESCUELA: Question<string>;
  GRADO: Question<number | null>;
  GRUPO: Question<string | null>;
}

export const personalDataInitialForm: IPersonalData = {
  FOLIO: new Question("FOLIO", 'Folio:', null, false),
  GENERO: new Question("GENERO", 'Yo soy:', null),
  NOMBRE: new Question("NOMBRE", "Me Llamo:", ""),
  EDAD: new Question("EDAD", "Tengo:", null),
  ESCUELA: new Question("ESCUELA", "Nombre de Escuela:", ""),
  GRADO: new Question("GRADO", "Grado:", null),
  GRUPO: new Question("GRUPO", "Grupo:", null, false),
};

const PersonalData = ({ onFinish }: IStepProps<IPersonalData>) => {

  const [form, setForm] = React.useState<IPersonalData>(personalDataInitialForm);

  const handleSummit = () => {
    if (validateForm(form))
      onFinish(form);
  };

  const complete = validateForm(form);

  return (
    <Grid container direction="column" className={styles.root}>
      <Grid item container justifyContent="center">
        <div className={styles.form}>

          <Grid item>
            <label htmlFor='Folio'>{form.FOLIO.label}</label>
            <input type="text" name='Folio'
              onChange={e => setForm({ ...form, FOLIO: form.FOLIO.setValue(e.target.value) })} />
          </Grid>

          <GenderSelect
            label={form.GENERO.label}
            value={form.GENERO.value}
            onChange={(sex: SEX) => setForm({ ...form, GENERO: form.GENERO.setValue(sex) })}
          />

          <Grid item>
            <label htmlFor='name'>{form.NOMBRE.label}</label>
            <input type="text" name='name'
              onChange={e => setForm({ ...form, NOMBRE: form.NOMBRE.setValue(e.target.value) })} />
          </Grid>

          <Grid item>
            <label htmlFor='age'>{form.EDAD.label}</label>
            <input
              type="number"
              name='age'
              min={1} max={99}
              onChange={e => setForm({ ...form, EDAD: form.EDAD.setValue(Number(e.target.value)) })}
            /> <span>años</span>
          </Grid>

          <Grid item>
            <label htmlFor='school_name'>{form.ESCUELA.label}</label>
            <input
              type="text"
              name='school_name'
              value={form.ESCUELA.value}
              onChange={e => setForm({ ...form, ESCUELA: form.ESCUELA.setValue(e.target.value) })} />
          </Grid>

          <Grid item container>

            <Grid item md={4}>
              <label htmlFor='grade'>{form.GRADO.label}</label>
              <input type="number" min={1} max={6} name='grade'
                defaultValue={(form.GRADO.value !== null) ? form.GRADO.value : undefined}
                onChange={e => setForm({ ...form, GRADO: form.GRADO.setValue(Number(e.target.value)) })} />
            </Grid>

            <Grid item >
              <label htmlFor='group'>{form.GRUPO.label}</label>
              <input type="text" name='group'
                defaultValue={(form.GRUPO.value != null) ? form.GRUPO.value : undefined}
                onChange={e => setForm({ ...form, GRUPO: form.GRUPO.setValue(e.target.value) })} />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item container justifyContent="center">
        <Button disabled={!complete} onClick={handleSummit}>Continuar</Button>
      </Grid>
    </Grid>
  )
};

export default PersonalData;