
import React from 'react';
import styles from './Button.module.scss';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {

}

const NextButton = (props: IProps) => {
    return (
        <>
            <button
                {...props}
                className={styles.btn}
            >
                {props.children}
            </button>
        </>
    );
}

export default NextButton;