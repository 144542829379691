import React from 'react';
import styles from './Btn.module.scss'

const Btn = ({ compact = false, value, onChange, image, label, width }:
  {
    compact?: boolean,
    value: string,
    label?: string,
    onChange: (value: string, s: boolean) => void,
    image?: string,
    width?: string
  }
) => {

  if (label === undefined)
    label = value;

  const [selected, setSelected] = React.useState<boolean>(false);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setSelected(!selected)
    onChange(value, selected);
  };

  return (
    <button className={`${styles.button} ${compact ? styles.compact : ""}`} onClick={handleOnClick}>
      <div className={styles.icon} style={{ margin: `0 auto`, width: width || '100%' }}>
        {image && <img src={image} alt="" height="100%" width="100%" />}

      </div>
      {compact ? null : <div className={styles.name}>{label}</div>}
      <div className={`${styles.checkbox}  ${selected ? styles.checked : ""}`}></div>
    </button>
  );
}

export default Btn;