import React from 'react';
import styles from './Confirm.module.scss'

const Confirm = (props: {
  onChange: (state: boolean) => void
}) => {
  const [state, setState] = React.useState<boolean | undefined>();

  return (<div style={{
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-evenly`
  }}>
    <div onClick={() => { setState(true); props.onChange(true) }}>
      <div style={{
        fontSize: `1.5rem`,
        fontWeight: `bold`
      }}>Si</div>
      <div className={`${styles.checkbox} ${(state === true) ? styles.checked : ""}`}>
      </div>
    </div>
    <div onClick={() => { setState(false); props.onChange(false) }}>
      <div style={{
        fontSize: `1.5rem`,
        fontWeight: `bold`
      }}>No</div>
      <div className={`${styles.checkbox} ${(state === false) ? styles.checked : ""}`}>
      </div>
    </div>
  </div >
  );
}

export default Confirm;