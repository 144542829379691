
import React from 'react';
import { IStepProps } from '../../FormLoader/types';

import Grid from '@mui/material/Grid';

import styles from './EmotionalInfo.module.scss'
import Button from '../button/Button';
import Question from '../../models/Question';
import validateForm from '../../utils/validateForm';

import sleep1Icon from '../../images/sleep1.svg'
import sleep2Icon from '../../images/sleep2.svg'
import sleep3Icon from '../../images/sleep3.svg'

import sadIcon from '../../images/SVG/triste.svg'
import angry from '../../images/SVG/personas-malas.svg'
import dontSpeep from '../../images/SVG/no-dormir.svg'
import preocupado from '../../images/SVG/preocupado.svg'
import feliz from '../../images/SVG/feliz.svg'
import dormir from '../../images/SVG/dormir.svg'
import escuela from '../../images/SVG/escuela.svg'
import si from '../../images/SVG/si.svg'
import no from '../../images/SVG/no.svg'

import Checkbox from '../Checkbox/Checkbox';
import { CONFIRM } from '../../const';
import Code from '../Code';

// enum COUNT {
//   lot = 'muchas veces',
//   not_many = 'pocas veces',
//   some_types = 'alguna vez',
//   never = 'nunca',
// }

enum SLEEP_LEVEL {
  like_it = 'Si me gusta',
  dont_liked = 'No me gusta',
  cant_sleep = 'Si me gusta, pero no puedo dormir',
}

// const SelectButtons = (props: { active: boolean, signalIcon: string, count: COUNT, onClick: (count: COUNT) => void }) => {
//   return (
//     <Grid item xs={6} sm={3} container onClick={e => props.onClick(props.count)}>
//       <Grid item container className={styles.selectButton} spacing={1}>
//         <Grid item container justifyContent="center">
//           <img src={props.signalIcon} alt="" height="auto" width="100%" />
//         </Grid>
//         <Grid item container justifyContent="center">
//           <Checkbox active={props.active} /><span style={{ fontSize: '1rem', fontWeight: 'bold', margin: ' 0px 5px' }}>{props.count}</span>
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// }

interface ISectionProps {
  code: string;
  icon: string;

  main: Question<string>;
  // count: Question<COUNT | null>;
  source: Question<string>;

  onMainChange: (main: Question<string>) => void;
  // onCountChange: (count: Question<COUNT | null>) => void;
  onSourceChange: (source: Question<string>) => void;
}

const Section = (props: ISectionProps) => {
  // const { count } = props;

  return (
    <Grid item container justifyContent="center" spacing={1}>
      <Grid item xs={1}>
        <img src={props.icon} alt="" height="auto" width="100%" />
      </Grid>
      <Grid item xs={11} container spacing={3}>
        <Grid item container>
          <Code>{props.code}</Code>
          <label htmlFor={props.main.label}>{props.main.label}</label>
          <input type="text" name={props.main.label}
            onChange={e => props.onMainChange(props.main.setValue(e.target.value))} />
        </Grid>

        {/* <Grid item container spacing={2}>
          <Grid item>
            <Code>{count.key}</Code>
            <label >{count.label}</label>
          </Grid>
          <Grid item container spacing={2}>
            <SelectButtons active={count.value === COUNT.lot} signalIcon={signal4Icon} count={COUNT.lot}
              onClick={c => {
                props.onCountChange(count.setValue(c));
                props.onSourceChange(props.source.setRequired(true));
              }} />
            <SelectButtons active={count.value === COUNT.not_many} signalIcon={signal3Icon} count={COUNT.not_many}
              onClick={c => {
                props.onCountChange(count.setValue(c));
                props.onSourceChange(props.source.setRequired(true));
              }} />
            <SelectButtons active={count.value === COUNT.some_types} signalIcon={signal2Icon} count={COUNT.some_types}
              onClick={c => {
                props.onCountChange(count.setValue(c));
                props.onSourceChange(props.source.setRequired(true));
              }} />
            <SelectButtons active={count.value === COUNT.never} signalIcon={signal1Icon} count={COUNT.never}
              onClick={c => {
                props.onCountChange(count.setValue(c));
                props.onSourceChange(props.source.setRequired(false));
              }} />
          </Grid>
          
        </Grid> */}

        <Grid item container>
          <Code>{props.source.key}</Code>
          <label htmlFor={props.source.label}>{props.source.label}</label>
          <input type="text" name={props.source.label}
            onChange={e => props.onSourceChange(props.source.setValue(e.target.value))} />
        </Grid>
      </Grid>
    </Grid >
  );
}

const SleepQuestion = (props: {
  code: string,
  like: Question<SLEEP_LEVEL | null>,
  why: Question<string>,
  onLikeChange: (main: Question<SLEEP_LEVEL | null>) => void;
  onWhyChange: (main: Question<string>) => void;
}) => {

  return (
    <Grid item container style={{}}>
      <Grid item xs={1}>
        <div>
          <img src={dormir} alt="" height="auto" width="100%" />
        </div>
      </Grid>
      <Grid item xs={11} container spacing={3}>
        <Grid item container>
          <Code>{props.code}</Code>
          <label htmlFor={props.like.label}>{props.like.label}</label>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4} container onClick={e => props.onLikeChange(props.like.setValue(SLEEP_LEVEL.like_it))}>
            <Grid item container className={styles.selectButton} spacing={1}>
              <Grid item container justifyContent="center">
                <div>
                  <img src={sleep1Icon} alt="" height="auto" width="100%" />
                </div>
              </Grid>
              <Grid item container justifyContent="center">
                <Checkbox active={props.like.value === SLEEP_LEVEL.like_it} /><span style={{ fontSize: '1rem', fontWeight: 'bold', margin: ' 0px 5px' }}>{SLEEP_LEVEL.like_it}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} container onClick={e => props.onLikeChange(props.like.setValue(SLEEP_LEVEL.dont_liked))}>
            <Grid item container className={styles.selectButton} spacing={1}>
              <Grid item container justifyContent="center">
                <div>
                  <img src={sleep2Icon} alt="" height="auto" width="100%" />
                </div>
              </Grid>
              <Grid item container justifyContent="center">
                <Checkbox active={props.like.value === SLEEP_LEVEL.dont_liked} /><span style={{ fontSize: '1rem', fontWeight: 'bold', margin: ' 0px 5px' }}>{SLEEP_LEVEL.dont_liked}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} container onClick={e => props.onLikeChange(props.like.setValue(SLEEP_LEVEL.cant_sleep))}>
            <Grid item container className={styles.selectButton} spacing={1}>
              <Grid item container justifyContent="center">
                <div>
                  <img src={sleep3Icon} alt="" height="auto" width="100%" />
                </div>
              </Grid>
              <Grid item container justifyContent="center">
                <Checkbox active={props.like.value === SLEEP_LEVEL.cant_sleep} /><span style={{ fontSize: '.9rem', fontWeight: 'bold', margin: ' 0px 5px' }}>{SLEEP_LEVEL.cant_sleep}</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
          <Code>{props.why.key}</Code>
          <label htmlFor={props.why.label}>{props.why.label}</label>
          <input type="text" name={props.why.label}
            onChange={e => props.onWhyChange(props.why.setValue(e.target.value))} />
        </Grid>

      </Grid>
    </Grid >
  );
}

const SchoolQuestion = (props: {
  code: string,
  like: Question<CONFIRM | null>,
  why: Question<string>,
  onLikeChange: (main: Question<CONFIRM | null>) => void;
  onWhyChange: (main: Question<string>) => void;
}) => {

  return (
    <Grid item container style={{}}>
      <Grid item xs={1}>
        <div>
          <img src={escuela} alt="" height="auto" width="100%" />
        </div>
      </Grid>
      <Grid item xs={11} container spacing={3}>
        <Grid item container>
          <Code>{props.code}</Code>
          <label htmlFor={props.like.label}>{props.like.label}</label>
        </Grid>
        <Grid item container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={4} container onClick={e => props.onLikeChange(props.like.setValue(CONFIRM.YES))}>
            <Grid item container className={styles.selectButton} spacing={1}>
              <Grid item container justifyContent="center">
                <div>
                  <img src={si} alt="" height="auto" width="100%" />
                </div>
              </Grid>
              <Grid item container justifyContent="center">
                <Checkbox active={props.like.value === CONFIRM.YES} /><span style={{ fontSize: '1rem', fontWeight: 'bold', margin: ' 0px 5px' }}>{CONFIRM.YES}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} container onClick={e => props.onLikeChange(props.like.setValue(CONFIRM.NO))}>
            <Grid item container className={styles.selectButton} spacing={1}>
              <Grid item container justifyContent="center">
                <div>
                  <img src={no} alt="" height="auto" width="100%" />
                </div>
              </Grid>
              <Grid item container justifyContent="center">
                <Checkbox active={props.like.value === CONFIRM.NO} /><span style={{ fontSize: '.9rem', fontWeight: 'bold', margin: ' 0px 5px' }}>{CONFIRM.NO}</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
          <Code>{props.why.key}</Code>
          <label htmlFor={props.why.label}>{props.why.label}</label>
          <input type="text" name={props.why.label}
            onChange={e => props.onWhyChange(props.why.setValue(e.target.value))} />
        </Grid>

      </Grid>
    </Grid >
  );
}

export interface IEmotionalInfo {
  what_you_do_when_you_feel_sad: Question<string>;
  // sad_count: Question<COUNT | null>;
  what_make_you_feel_sad: Question<string>;

  what_you_do_when_you_feel_mad: Question<string>;
  // mad_count: Question<COUNT | null>;
  what_make_you_feel_mad: Question<string>;

  what_you_do_when_you_feel_scare: Question<string>;
  // scare_count: Question<COUNT | null>;
  what_make_you_feel_scare: Question<string>;

  what_you_do_when_you_feel_worried: Question<string>;
  // worried_count: Question<COUNT | null>;
  what_make_you_feel_worried: Question<string>;

  what_you_do_when_you_feel_happy: Question<string>;
  // happy_count: Question<COUNT | null>;
  what_make_you_feel_happy: Question<string>;

  do_you_like_to_sleep: Question<SLEEP_LEVEL | null>;
  why_do_you_like_to_sleep: Question<string>;

  do_you_like_to_go_to_school: Question<CONFIRM | null>;
  why_do_you_like_to_go_to_school: Question<string>;
};

export const emotionalInfoForm: IEmotionalInfo = {
  what_you_do_when_you_feel_sad: new Question("D-9", '¿Qué haces tú cuando te sientes triste?', ""),
  what_make_you_feel_sad: new Question("D-9-B", '¿Qué te hace sentir triste?', "", false),

  what_you_do_when_you_feel_mad: new Question("D-10", '¿Qué haces tú cuando te sientes enojado o enojada?', ""),
  what_make_you_feel_mad: new Question("D-10-B", '¿Qué te hace sentir enojado o enojada?', "", false),

  what_you_do_when_you_feel_scare: new Question("AN-11", ' ¿Qué haces tú cuando sientes miedo?', ""),
  what_make_you_feel_scare: new Question("AN-11-B", '¿Qué te hace sentir miedo?', "", false),

  what_you_do_when_you_feel_worried: new Question("AN-12", '¿Qué haces tú cuando te sientes preocupado o preocupada?', ""),
  what_make_you_feel_worried: new Question("AN-12-B", '¿Qué te hace sentir preocupado o preocupada?', "", false),

  what_you_do_when_you_feel_happy: new Question("D-13", '¿Qué haces tú cuando te sientes feliz?', ""),
  what_make_you_feel_happy: new Question("D-13-B", '¿Qué te hace sentir feliz?', "", false),

  do_you_like_to_sleep: new Question("AN-14", '¿Te gusta dormir?', null),
  why_do_you_like_to_sleep: new Question("AN-14-A", '¿Por qué?', ""),
  do_you_like_to_go_to_school: new Question("AN-15", '¿Te gusta ir a la escuela?', null),
  why_do_you_like_to_go_to_school: new Question("AN-15-A", '¿Por qué?', ""),
};

const EmotionalInfo = ({ onFinish }: IStepProps<IEmotionalInfo>) => {

  const [form, setForm] = React.useState<IEmotionalInfo>(emotionalInfoForm);

  const handleSummit = () => {
    if (validateForm(form))
      onFinish(form);
  };

  const complete = validateForm(form);

  return (
    <Grid container direction="column" className={styles.root}>
      <Grid item container justifyContent="center" spacing={8}>

        <Section
          code={form.what_you_do_when_you_feel_sad.key}
          icon={sadIcon}
          main={form.what_you_do_when_you_feel_sad}
          source={form.what_make_you_feel_sad}
          onMainChange={e => setForm({ ...form, what_you_do_when_you_feel_sad: e })}
          onSourceChange={e => setForm({ ...form, what_make_you_feel_sad: e })}
        />

        <Section
          code={form.what_you_do_when_you_feel_mad.key}
          icon={angry}
          main={form.what_you_do_when_you_feel_mad}
          source={form.what_make_you_feel_mad}
          onMainChange={e => setForm({ ...form, what_you_do_when_you_feel_mad: e })}
          onSourceChange={e => setForm({ ...form, what_make_you_feel_mad: e })}
        />

        <Section
          code={form.what_you_do_when_you_feel_scare.key}
          icon={dontSpeep}
          main={form.what_you_do_when_you_feel_scare}
          source={form.what_make_you_feel_scare}
          onMainChange={e => setForm({ ...form, what_you_do_when_you_feel_scare: e })}
          onSourceChange={e => setForm({ ...form, what_make_you_feel_scare: e })}
        />

        <Section
          code={form.what_you_do_when_you_feel_worried.key}
          icon={preocupado}
          main={form.what_you_do_when_you_feel_worried}
          source={form.what_make_you_feel_worried}
          onMainChange={e => setForm({ ...form, what_you_do_when_you_feel_worried: e })}
          onSourceChange={e => setForm({ ...form, what_make_you_feel_worried: e })}
        />

        <Section
          code={form.what_you_do_when_you_feel_happy.key}
          icon={feliz}
          main={form.what_you_do_when_you_feel_happy}
          source={form.what_make_you_feel_happy}
          onMainChange={e => setForm({ ...form, what_you_do_when_you_feel_happy: e })}
          onSourceChange={e => setForm({ ...form, what_make_you_feel_happy: e })}
        />
        <SleepQuestion
          code={form.do_you_like_to_sleep.key}
          like={form.do_you_like_to_sleep}
          why={form.why_do_you_like_to_sleep}

          onLikeChange={e => setForm({ ...form, do_you_like_to_sleep: e })}
          onWhyChange={e => setForm({ ...form, why_do_you_like_to_sleep: e })}
        />

        <SchoolQuestion
          code={form.do_you_like_to_go_to_school.key}
          like={form.do_you_like_to_go_to_school}
          why={form.why_do_you_like_to_go_to_school}

          onLikeChange={e => setForm({ ...form, do_you_like_to_go_to_school: e })}
          onWhyChange={e => setForm({ ...form, why_do_you_like_to_go_to_school: e })}
        />
      </Grid>
      <Grid item container justifyContent="center">
        <Button disabled={!complete} onClick={handleSummit}>Continuar</Button>
      </Grid>
    </Grid>
  )
};

export default EmotionalInfo;