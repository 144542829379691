import React from 'react';
import { useParams } from 'react-router-dom';
import FormProvider from '../formDataStore/formProvider';
import useFormLoader from '../FormLoader/useFormLoader';

interface IProps { }

export const gePutUrl = (codigo: string) => `https://pjgvzztrpl.execute-api.us-west-2.amazonaws.com/dev/form-record/${codigo}`

const Form = (props: IProps) => {
    let { codigo } = useParams<Record<string, string | undefined>>();
    const form = useFormLoader(codigo);
    return form;
}

export default Form;