import React from 'react';

import Intro from './form-components/Intro/Intro';
import Input, { TBaseInput, TInput } from './form-components/Input/Input';
import Page from './form-components/Page';
import Paragraph from './form-components/Paragraph';
import Stepper from './form-components/Stepper';
import { FormElement, FormConfig, TGlobals, TFormComponent } from './types';
import Confirmation from './form-components/Confirmation/Confirmation';
import { useFormContext } from '../formDataStore/formProvider';
import { GenderSelect } from '../components/steps/PersonalData';
import { SEX } from '../const';
import { FamilySelect } from '../components/steps/WhoDoYouLiveWith';
import { WhoCanDie } from '../components/steps/DeathAwareness';
import Instrucciones from './form-components/Instrucciones/Instrucciones';

// type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
// type StatusType = typeof FORM_COMPONENTS[keyof typeof FORM_COMPONENTS];

var GLOBALS: TGlobals | undefined;

export type TV1Elements = {
  type: 'select-gender' | 'select-family' | 'death-select';
  value: TBaseInput
}

const V1Component: React.FC<
  TFormComponent
  & FormElement
> = (props) => {
  const { state, dispatch } = useFormContext();

  const updateField = (code: string, value: string | string[] | number[]) => {
    if (state.current_record)
      dispatch({
        type: "UPDATE_FIELD_VALUE",
        payload: {
          form_code: props.globals.code, record_id: state.current_record.record_id,
          field: { [code]: value }
        }
      })
  }
  const getValue = (code: string) => {
    return (state.current_record !== undefined) ? state.current_record.fields[code] : null;
  }

  switch (props.type) {
    case 'select-gender':
      return <GenderSelect
        label={props.value.label}
        value={getValue(props.value.code)}
        onChange={(sex: SEX) => updateField(props.value.code, sex)}
      />
    case 'select-family':
      return <FamilySelect
        label={props.value.label}
        code={props.value.code}
        values={getValue(props.value.code) || []}
        onChange={(values) => updateField(props.value.code, values)}
      />
    case 'death-select':
      return <WhoCanDie
        label={props.value.label}
        code={props.value.code}
        values={getValue(props.value.code) || []}
        onChange={(values) => updateField(props.value.code, values)}
      />
    default:
      return <>Unknow Component</>;
  }
}

function getV1Component(e: FormElement, globals: TGlobals, key?: string): JSX.Element {

  globals = handleGlobals(globals);

  var props: {
    key?: string
  } = {};

  if (key !== undefined)
    props.key = key;

  switch (e.type) {
    case 'intro':
      return <Intro {...(e)} />
    case 'select-gender':
      return <V1Component {...(e)} globals={globals} />
    case 'select-family':
      return <V1Component {...(e)} globals={globals} />
    case 'death-select':
      return <V1Component {...(e)} globals={globals} />
    default:
      return <>Unknow Component</>;
  }
}


export function getComponent(e: FormElement, globals: TGlobals, key?: string): JSX.Element {

  globals = handleGlobals(globals);

  var props: {
    key?: string
  } = {};

  if (key !== undefined)
    props.key = key;

  switch (e.type) {
    case 'stepper':
      return <Stepper {...(e)} globals={globals} />
    case 'page':
      return <Page {...(e)} />
    case 'paragraph':
      return <Paragraph {...(e)} />
    case 'confirmation':
      return <Confirmation {...(e)} />
    case 'instrucciones':
      return <Instrucciones />
    case 'input':
      return <Input {...(e)} globals={globals} />
    // case 'group':
    //   return <Input {...(e)} globals={globals} />
    default:
      return getV1Component(e, globals, key);
  }
}

export function getComponents(form: FormConfig, globals?: TGlobals, key?: string): JSX.Element | null {

  globals = handleGlobals(globals);

  if (form instanceof Array) {
    return (
      <>{form.map((element, index) => getComponents(element, globals, index.toString()))}</>
    )
  } else {
    if (form.hasOwnProperty('type')) {
      return getComponent(form, globals, key);
    }
    else {
      throw new Error('No Type Defined in the Config File');
    }
  }

}

function handleGlobals(globals: TGlobals | undefined): TGlobals {
  if (globals !== undefined) {
    GLOBALS = globals;
    return globals
  } else {
    if (GLOBALS !== undefined) return GLOBALS;
    return {} as TGlobals
  }
}

