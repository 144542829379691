import React from 'react';


import helloImage from '../../images/hello.svg';
import questionMark from '../../images/question-mark.svg';

import Grid from '@mui/material/Grid';
import Button from '../button/Button';
import styles from './Intro.module.scss'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { Theme } from '@mui/material/styles/createTheme';
import { IStepProps } from '../../FormLoader/types';


const Intro = ({ onFinish }: IStepProps) => {

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Grid container spacing={5} direction="column" className={styles.root}>
      <Grid item container justifyContent="center">
        <Grid item container md={6} justifyContent={matches ? "flex-end" : "center"}>
          <Grid item>
            <img alt="Hello" src={helloImage} style={{ maxWidth: "12rem", width: "50vw", height: "auto" }} />
          </Grid>
        </Grid>
        <Grid item container md={6} alignItems="center">
          <p>
            Gracias por <b>ayudarnos</b> a contestar estas <br />
            preguntas. Las respuestas a cada pregunta son <br />
            individuales, cada uno tiene su propia respuesta <br />
            y esa es la que necesitamos.
          </p>
        </Grid>
        <Grid item container md={6} justifyContent={"flex-end"} alignItems="center">
          <p style={{ width: (matches ? "80%" : "100%") }}>
            Si tienes alguna duda, levanta tu mano
            e iremos a tu lugar con mucho gusto.
          </p>
        </Grid>
        <Grid item container md={6} justifyContent={matches ? "flex-start" : "center"}>
          <img alt="Question Mark" src={questionMark} style={{ maxWidth: "17rem", width: "40vw", height: "auto" }} />
        </Grid>
      </Grid>
      <Grid item container justifyContent="center">
        <Button onClick={onFinish} >Iniciar</Button>
      </Grid>
    </Grid>
  )
}

export default Intro;