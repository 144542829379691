import Grid from '@mui/material/Grid';
import React from 'react';
import Code from './components/Code';
import styles from './Btn.module.scss'
import { usePersistedState } from '../../../formDataStore/usePersistedState';
import { FormConfig, FormElement, TFormComponent, TGlobals } from '../../types';
import { useFormContext } from '../../../formDataStore/formProvider';
import { getComponents } from '../../LayoutProcesor';
import SingleOptionSelect from './components/single-option';
import MultipleOptionsSelect from './components/multiple-options';
import stringJsxTemplate from '../../../utils/stringJsxTemplate';

export type TBaseInput = {
  code: string,
  hide_code?: boolean,
  label: string,
  content?: FormConfig,
  icon?: string
}

export type TInput = {
  type: "input"
  value: {
    type: 'text' | 'short-text',
  } & TBaseInput
} | {
  type: "input"
  value: {
    type: 'multiple-options' | 'single-option',
    options: string[],
    other?: boolean,
  } & TBaseInput
} | {
  type: "input"
  value: {
    type: 'template',
    template: string,
    label?: string
  } & Omit<TBaseInput, 'label'>
}


const getSubContent = (content: FormConfig) => {
  return <Grid item xs={11}>{getComponents(content)}</Grid>
};

const Input: React.FC<
  TFormComponent
  & TInput
> = (props) => {

  const { value: { code, label, content }, globals } = props;
  const { state, dispatch } = useFormContext();

  React.useEffect(() => {
    if (state.current_record)
      dispatch({
        type: "UPDATE_FIELD_VALUE",
        payload: {
          form_code: globals.code,
          record_id: state.current_record.record_id, field: { [code]: "" }
        }
      })
    return () => { }
  }, []);


  const updateField = (value: string | string[]) => {
    if (state.current_record)
      dispatch({
        type: "UPDATE_FIELD_VALUE",
        payload: {
          form_code: globals.code, record_id: state.current_record.record_id,
          field: { [code]: value }
        }
      })
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {

  }

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    updateField(e.target.value)
  }

  const getInputByType = () => {
    switch (props.value.type) {
      case "text":
        return (
          <textarea
            name={code}
            onBlurCapture={(e) => { updateField(e.target.value) }}
          />
        )
      case "short-text":
        return (
          <input
            type="text"
            name={code}
            onChange={e => updateField(e.target.value)}
          />
        );
      case "template":

        var input =
          <input
            style={{ width: 100 }}
            type="text"
            name={code}
            onChange={e => updateField(e.target.value)}
          />
          ;

        var complateInput = stringJsxTemplate(props.value.template, undefined, input);

        return (
          <Grid><label>{complateInput}</label></Grid>
        );

      case "single-option":
        return <SingleOptionSelect onChange={updateField} options={props.value.options} />
      case "multiple-options":
        return (
          <Grid container justifyContent="center">
            <Grid item xs={11} container>
              <MultipleOptionsSelect onChange={updateField} options={props.value.options} other={!!props.value.other} />
            </Grid>
          </Grid>
        )
      default:
        return <>unknow component</>
      // return <V1ComponentLoader type={props.value.type} onChange={updateField} />
    }
  }

  var icon = (props.value.icon !== undefined) ? (
    <Grid item xs={1}>
      <img src={`/images/` + props.value.icon} alt="" height="75%" width="75%" />
    </Grid>
  ) : undefined;

  return (
    <Grid item xs={12}>
      <Grid container>
        {icon}
        <Grid item xs={icon ? 11 : 12}>
          {(props.value.hide_code !== true) && <Code>{code}</Code>}
          <label htmlFor={code}>{label}</label>
          {getInputByType()}
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={"right"}>
            {(content !== undefined) && getSubContent(content)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Input;