import { FormConfig } from "../../FormLoader/types";


export const formConfig: FormConfig = {
    type: "stepper",
    config: {
        onFinish: {
            save: true,
            confirmation_layout: {
                type: 'confirmation',
                value: {}
            },
            restart: true
        }
    },
    value: [
        {
            // title: "test",
            content: [
                {
                    type: 'intro',
                    value: {}
                },
            ]
        },
        {
            content: [
                {
                    type: 'page',
                    value:
                    {
                        content: [
                            {
                                type: 'input',
                                value: {
                                    type: "short-text",
                                    code: "FOLIO",
                                    hide_code: true,
                                    label: "Folio"
                                }
                            },
                            {
                                type: 'select-gender',
                                value: {
                                    code: "GENERO",
                                    hide_code: true,
                                    label: "¿Yo soy?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "NOMBRE",
                                    hide_code: true,
                                    label: "¿Me llamo?"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "template",
                                    code: "EDAD",
                                    hide_code: true,
                                    template: "Tengo: {0} años",
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "ESCUELA",
                                    hide_code: true,
                                    label: "Nombre de escuela"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "template",
                                    code: "GRADO",
                                    hide_code: true,
                                    template: "Grado: {0}"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "template",
                                    code: "GRUPO",
                                    hide_code: true,
                                    template: "Grupo: {0}"
                                }
                            },
                        ]
                    }
                },

            ]
        },
        {
            content: [
                {
                    type: 'page',
                    value:
                    {
                        content: [
                            {
                                type: 'input',
                                value: {
                                    type: "multiple-options",
                                    options: ['Mamá', 'Madrastra', 'Hermanas', 'Mascotas', 'Papá', 'Padrastro', 'Hermanos'],
                                    other: true,
                                    code: "EF-1",
                                    label: "¿Con quién vives?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "RA-2",
                                    label: "¿Quién te cuida?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "multiple-options",
                                    options: ['Familia', 'Amistades'],
                                    other: true,
                                    code: "RA-3",
                                    label: "¿A quién le platicas cuando sientes felicidad, tristeza, enojo o miedo?",
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "RA-4",
                                    label: "¿Tienes amigos o amigas?",
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "A-5",
                                    label: "¿Quién te quiere mucho?",
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "A-6",
                                    label: "¿Cómo te demuestra que te quiere la persona que te cuida?",
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "A-7",
                                    label: "¿Tú, a quién quieres mucho?",
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "A-8",
                                    label: "¿Cómo le demuestras a esa persona que la quieres?",
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "E-9",
                                    label: "¿Qué haces cuando te sientes triste?",
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "E-10",
                                    label: "¿Qué haces cuando te sientes enojado o enojada?",
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "E-11",
                                    label: "¿Qué haces cuando sientes miedo?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "E-12",
                                    label: "¿Qué haces cuando te sientes preocupado o preocupada?"
                                }
                            },

                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "E-13",
                                    label: "¿Qué haces cuando te sientes feliz?"
                                }
                            },

                            
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No', 'Si me gusta pero no puedo dormir'],
                                    code: "FRP-14",
                                    label: "¿Te gusta dormir?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "ERP-14-A",
                                                label: "¿Por qué?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-15",
                                    label: "¿Te gusta ir a la escuela?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-15-A",
                                                label: "¿Por qué?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'death-select', // compose-input
                                value: {
                                    code: "CMU-16",
                                    label: "¿Quiénes pueden morir?",
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "CMI-17",
                                    icon: "SVG/tumba.svg",
                                    label: "¿Después de que alguien muere, puede revivir?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    icon: "SVG/calaca.svg",
                                    code: "CMSFV-18",
                                    label: "Después de que muere una persona o un animal, ¿el cuerpo deja de funcionar?"
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "text",
                                    code: "CM-16",
                                    label: "¿Qué pasa cuando una persona muere?"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "CMCF-20",
                                    label: "¿Por qué puede morir una persona o animal?",
                                }
                            },

                            
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-21",
                                    label: "¿Alguna vez has sentido que quieres dormir y no despertar nunca?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-21-A",
                                                label: "¿Cuándo?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-22",
                                    label: "¿Alguna vez has sentido que tienes muchas ganas de llorar?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-22-A",
                                                label: "¿Cuándo?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "E-23",
                                    label: "¿Es bueno llorar?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "E-23-A",
                                                label: "¿Por qué?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-24",
                                    label: "¿Alguna vez has sentido que te quieres hacer daño?",
                                    icon: "SVG/corazon-roto.svg",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-24-A",
                                                label: "¿Cuándo?"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-25",
                                    label: "¿Alguna vez has probado bebidas alcohólicas?",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-25-A",
                                                label: "¿Cuándo?"
                                            }
                                        },
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-26",
                                    label: "¿Alguna vez has fumado?",
                                    icon: "SVG/fumar_v2.svg",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-26-A",
                                                label: "¿Porqué?"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'input',
                                value: {
                                    type: "single-option",
                                    options: ['SI', 'No'],
                                    code: "FRP-27",
                                    label: "¿Te gusta la ropa que usas?",
                                    icon: "SVG/lentes-cool.svg",
                                    content: [
                                        {
                                            type: 'input',
                                            value: {
                                                type: "text",
                                                code: "FRP-27-A",
                                                label: "¿Por qué?"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "FRP-28",
                                    label: "¿En qué te gustaría trabajar cuándo seas grande?",
                                    icon: "SVG/corazon.svg"
                                }
                            },
                            {
                                type: 'input', // compose-input
                                value: {
                                    type: "text",
                                    code: "FRP-29",
                                    label: "¿Qué es lo que más te hace reír?",
                                }
                            },


                        ]
                    }
                },

            ]
        }

    ]
}