
import React from 'react';
import { IStepProps } from '../../FormLoader/types';
import Grid from '@mui/material/Grid';

import styles from './WhoDoYouLiveWith.module.scss'
import Button from '../button/Button';
import Question from '../../models/Question';
import validateForm from '../../utils/validateForm';
import Btn from '../Btn/Btn';

// Images
import mascotas from '../../images/Persons/Asset 3.svg';
import vecinos from '../../images/Persons/Asset 4.svg';
import vecinas from '../../images/Persons/Asset 5.svg';
import amigas from '../../images/Persons/Asset 6.svg';
import amigos from '../../images/Persons/Asset 7.svg';
import abuela from '../../images/Persons/Asset 8.svg';
import abuelo from '../../images/Persons/Asset 9.svg';
import tias from '../../images/Persons/Asset 10.svg';
import tios from '../../images/Persons/Asset 11.svg';
import hermanas from '../../images/Persons/Asset 12 large.svg';
import hermanos from '../../images/Persons/Asset 13.svg';
import mama_sm from '../../images/Persons/Asset 16.svg';
import pareja_papa from '../../images/Persons/Asset 17.svg';
import pareja_mama from '../../images/Persons/Asset 18.svg';
import papa_sm from '../../images/Persons/Asset 19.svg';
import papa from '../../images/Persons/Asset 20.svg';
import mama from '../../images/Persons/Asset 21.svg';

import te_cuida from '../../images/SVG/te-cuida.svg';
import te_quiere from '../../images/SVG/te-quiere.svg';
import te_cuidan from '../../images/SVG/te-cuidan.svg';
import te_quieres from '../../images/SVG/te-quieres.svg';
import querer from '../../images/SVG/querer.svg';
import Code from '../Code';

const GroupedBtns = ({ title, icon, buttons }: {
  title?: string,
  icon: string,
  buttons: { label: string, value: string, icon: string, onChange: (value: string) => void }[]
}) => {
  return (
    <Grid item container justifyContent="center">
      <Grid item xs={12} style={{ height: 50, margin: 'auto 0' }} >
        <img src={icon} alt="" height="100%" width="100%" />
      </Grid>

      <Grid item xs={12} style={{ margin: 'auto', textAlign: 'center' }}>
        <span style={{ fontSize: '1.1rem' }}>{title}</span>
      </Grid>

      <Grid item container justifyContent="center">
        {buttons.map(button => {
          return (
            <Btn compact label={button.label} value={button.value} onChange={(value) => button.onChange(value)} image={button.icon} width='70px' />
          );
        })}
      </Grid>
    </Grid>
  );
}

export const FamilySelect = (props: { code: string, label: string, values: string[], onChange: (values: string[]) => void }) => {

  const handleOnChange = (value: string) => {
    if (props.values.indexOf(value) > -1)
      props.onChange(props.values.filter(v => v !== value));
    else
      props.onChange([...props.values, value])
  };

  return (
    <Grid item container className={styles.familySelect}>
      <Grid item container xs={12} alignItems="center">
        <Code>{props.code}</Code>
        <label style={{ fontSize: `2rem` }} htmlFor="name">{props.label}</label>
      </Grid>

      <Grid item xs={12} container spacing={2} >
        <Grid item xs={12} container className={``}>

          <Grid item xs={12}  container justifyContent="center" alignItems="center">
            <Grid>
              <Btn label="Mamá" value={`1`} onChange={(value) => handleOnChange(value)} image={mama} width='70px' />
            </Grid>

            <Grid>
              <Btn label="Papá" value={`2`} onChange={(value) => handleOnChange(value)} image={papa} width='70px' />
            </Grid>
            <Grid>
              <Btn label="Padrastro" value={`3`} onChange={(value) => handleOnChange(value)} image={pareja_papa} width='70px' />
            </Grid>

            <Grid>
              <Btn label="Madrastra" value={`4`} onChange={(value) => handleOnChange(value)} image={pareja_mama} width='70px' />
            </Grid>

            <Grid>
              <Btn label="Hermanos" value={`7`} onChange={(value) => handleOnChange(value)} image={hermanos} width='60px' />
            </Grid>

            <Grid>
              <Btn label="Hermanas" value={`8`} onChange={(value) => handleOnChange(value)} image={hermanas} width='90px' />
            </Grid>
            <Grid  >
              <Btn label="Mascotas" value={`17`} onChange={(value) => handleOnChange(value)} image={mascotas} width='70px' />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Grid >
  );
}

export interface IWhoDoYouLiveWith {
  who_do_you_live_with: Question<string[]>;
  who_takes_care_of_you: Question<string>;
  who_likes_you: Question<string>;
  who_likes_you_as_it_shows: Question<string>;
  who_do_you_like: Question<string>;
  who_do_you_like_how_you_shows: Question<string>;
};

export const whoDoYouLiveWithForm: IWhoDoYouLiveWith = {
  who_do_you_live_with: new Question("EF-1", '¿Con quién vives?', []),
  who_takes_care_of_you: new Question("RA-2", '¿Quién te cuida?', ""),
  who_likes_you: new Question("A-3", '¿Quién te quiere mucho?', ""),
  who_likes_you_as_it_shows: new Question("A-4", '¿Cómo te demuestra que te quiere la persona que te cuida?', ""),
  who_do_you_like: new Question("A-5", '¿Tú, a quién quieres mucho? ', ""),
  who_do_you_like_how_you_shows: new Question("A-6", '¿Cómo le demuestras a esa persona que la quieres? ', ""),
};

const WhoDoYouLiveWith = ({ onFinish }: IStepProps<IWhoDoYouLiveWith>) => {

  const [form, setForm] = React.useState<IWhoDoYouLiveWith>(whoDoYouLiveWithForm);

  const handleSummit = () => {
    onFinish(form);
  };

  return (
    <Grid container spacing={2}>
      <Grid item container justifyContent="center" spacing={4}>

        <Grid item container >
          <FamilySelect
            code={form.who_do_you_live_with.key}
            label={form.who_do_you_live_with.label}
            values={form.who_do_you_live_with.value}
            onChange={(values: string[]) => setForm({ ...form, who_do_you_live_with: form.who_do_you_live_with.setValue(values) })}
          />
        </Grid>

        <Grid item container>

          <Grid item container>
            <Grid item xs={1}>
              <img src={te_cuida} alt="" height="75%" width="75%" />
            </Grid>
            <Grid item xs={10}>
              <Code>{form.who_takes_care_of_you.key}</Code>
              <label style={{ fontSize: `2rem` }} htmlFor={form.who_takes_care_of_you.key}>{form.who_takes_care_of_you.label}</label>
              <input type="text" name={form.who_takes_care_of_you.key}
                onChange={e => setForm({ ...form, who_takes_care_of_you: form.who_takes_care_of_you.setValue(e.target.value) })}
              />
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item xs={1}>
              <img src={te_quiere} alt="" height="75%" width="75%" />
            </Grid>
            <Grid item xs={10}>
              <Code>{form.who_likes_you.key}</Code>
              <label style={{ fontSize: `2rem` }} htmlFor={form.who_likes_you.key}>{form.who_likes_you.label}</label>
              <input type="text" name={form.who_likes_you.key}
                onChange={e => setForm({ ...form, who_likes_you: form.who_likes_you.setValue(e.target.value) })}
              />
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item xs={1}>
              <img src={te_cuidan} alt="" height="75%" width="75%" />
            </Grid>
            <Grid item xs={10}>
              <Code>{form.who_likes_you_as_it_shows.key}</Code>
              <label style={{ fontSize: `2rem` }} htmlFor={form.who_likes_you_as_it_shows.key}>{form.who_likes_you_as_it_shows.label}</label>
              <textarea name={form.who_likes_you_as_it_shows.key}
                style={{ height: 150 }}
                onChange={e => setForm({ ...form, who_likes_you_as_it_shows: form.who_likes_you_as_it_shows.setValue(e.target.value) })}
              ></textarea>
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item xs={1}>
              <img src={te_quieres} alt="" height="75%" width="75%" />
            </Grid>
            <Grid item xs={10}>
              <Code>{form.who_do_you_like.key}</Code>
              <label style={{ fontSize: `2rem` }} htmlFor={form.who_do_you_like.key}>{form.who_do_you_like.label}</label>
              <input
                type="text"
                name={form.who_do_you_like.key}
                onChange={e => setForm({ ...form, who_do_you_like: form.who_do_you_like.setValue(e.target.value) })}
              />
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item xs={1}>
              <img src={querer} alt="" height="75%" width="75%" />
            </Grid>
            <Grid item xs={10}>
              <Code>{form.who_do_you_like_how_you_shows.key}</Code>
              <label style={{ fontSize: `2rem` }} htmlFor={form.who_do_you_like_how_you_shows.key}>{form.who_do_you_like_how_you_shows.label}</label>
              <textarea
                name={form.who_do_you_like_how_you_shows.key}
                onChange={e => setForm({ ...form, who_do_you_like_how_you_shows: form.who_do_you_like_how_you_shows.setValue(e.target.value) })}
                rows={4}
                style={{ height: 150 }}
              >
              </textarea>
            </Grid>
          </Grid>

        </Grid>
      </Grid>

      <Grid item container justifyContent="center">
        <Button onClick={handleSummit}>Continuar</Button>
      </Grid>
    </Grid>
  )
};

export default WhoDoYouLiveWith;