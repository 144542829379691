
import React from 'react';
import { IStepProps } from '../../FormLoader/types';
import { CONFIRM } from '../../const';

import Grid from '@mui/material/Grid';

import styles from './WhoDoYouLiveWith.module.scss'
import Button from '../button/Button';
import Question from '../../models/Question';
import validateForm from '../../utils/validateForm';
import Btn from '../Btn/Btn';

// Images
import mascotas from '../../images/Persons/Asset 3.svg';

import enfermos from '../../images/SVG/enfermos.svg';
import personas_malas from '../../images/SVG/personas-malas.svg';
import yo from '../../images/SVG/yo.svg';
import tumba from '../../images/SVG/tumba.svg';
import calaca from '../../images/SVG/calaca.svg';
import amigos from '../../images/SVG/amigos.svg';

import abuelos from '../../images/Persons/ancianosv2.svg';
import familia from '../../images/Persons/familia.svg';

import hermanas from '../../images/Persons/Asset 12 large.svg';
import hermanos from '../../images/Persons/Asset 13.svg';
import papa from '../../images/Persons/Asset 20.svg';
import mama from '../../images/Persons/Asset 21.svg';
import Code from '../Code';
import Confirm from '../../FormLoader/form-components/Confirm/Confirm';

const Options = [
  {
    value: 1,
    label: "Animales (mascotas)",
    image: mascotas,
    size: `70px`
  },
  {
    value: 2,
    label: "Enfermos",
    image: enfermos,
    size: `70px`
  },
  {
    value: 4,
    label: "Ancianos",
    image: abuelos,
    size: `70px`
  },
  {
    value: 6,
    label: "Personas malas",
    image: personas_malas,
    size: `70px`
  },
  {
    value: 7,
    label: "Familia",
    image: familia,
    size: `70px`
  },
  {
    value: 9,
    label: "Papá",
    image: papa,
    size: `70px`
  },
  {
    value: 10,
    label: "Mamá",
    image: mama,
    size: `70px`
  },
  {
    value: 11,
    label: "Yo",
    image: yo,
    size: `70px`
  },
  {
    value: 12,
    label: "Amistades",
    image: amigos,
    size: `70px`
  },
  {
    value: 12,
    label: "Todos",
    size: `70px`
  },
]

export const WhoCanDie = (props: { code: string, label: string, values: number[], onChange: (values: number[]) => void }) => {

  const handleOnChange = (value: number) => {
    if (props.values.indexOf(value) > -1)
      props.onChange(props.values.filter(v => v !== value));
    else
      props.onChange([...props.values, value])
  };

  return (
    <Grid item container className={styles.familySelect}>
      <Grid item container xs={12} alignItems="center">
        <Code>{props.code}</Code>
        <label htmlFor="name">{props.label}</label>
      </Grid>

      <Grid item xs={12} container spacing={2} >
        <Grid item xs={12} container >

          <Grid item xs={12} container justifyContent="center" >
            {Options.map(o =>
              <Grid>
                <Btn
                  value={o.value.toString()}
                  label={o.label}
                  onChange={(value) => handleOnChange(Number(value))}
                  image={o.image}
                  width={o.size}
                />
              </Grid>
            )}
          </Grid>

        </Grid>
      </Grid>
    </Grid >
  );
}


export interface IDeathAwareness {
  who_can_die: Question<number[]>;
  can_sameone_revive: Question<CONFIRM | null>;
  do_the_body_stop_working_after_death: Question<CONFIRM | null>;
  what_happen_someone_can_die: Question<string>;
  why_someone_can_die: Question<string>;
};

export const deathAwarenessForm: IDeathAwareness = {
  who_can_die: new Question("CMU-16", '¿Quiénes pueden morir?', []),
  can_sameone_revive: new Question("CMI-17", '¿Después de que alguien muere, puede revivir?', null),
  do_the_body_stop_working_after_death: new Question("CMSFV-18", 'Después de que muere una persona o un animal, ¿el cuerpo deja de funcionar?', null),
  what_happen_someone_can_die: new Question("CM-19", '¿Qué pasa cuando una persona se muere? ', ""),
  why_someone_can_die: new Question("CMCF-20", '¿Por qué puede morir una persona o animal?', ""),
};

const DeathAwareness = ({ onFinish }: IStepProps<IDeathAwareness>) => {

  const [form, setForm] = React.useState<IDeathAwareness>(deathAwarenessForm);

  const handleSummit = () => {
    if (validateForm(form))
      onFinish(form);
  };

  const complete = validateForm(form);

  return (
    <Grid container direction="column" className={styles.root}>
      <Grid item container justifyContent="center">

        <WhoCanDie
          code={form.who_can_die.key}
          label={form.who_can_die.label}
          values={form.who_can_die.value}
          onChange={(values) => setForm({ ...form, who_can_die: form.who_can_die.setValue(values) })}
        />

        <Grid item container>
          <Grid item xs={1}>
            <img src={tumba} alt="" height="75%" width="75%" />
          </Grid>
          <Grid item xs={11} container>
            <Grid item xs={12}>
              <Code>{form.can_sameone_revive.key}</Code>
              <label >{form.can_sameone_revive.label}</label>
            </Grid>
            <Grid item xs={12}>
              <Confirm onChange={s => {
                if (s)
                  form.can_sameone_revive.setValue(CONFIRM.YES)
                else
                  form.can_sameone_revive.setValue(CONFIRM.NO)
              }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item xs={1}>
            <img src={calaca} alt="" height="75%" width="75%" />
          </Grid>
          <Grid item xs={11} container>
            <Grid item xs={12}>
              <Code>{form.do_the_body_stop_working_after_death.key}</Code>
              <label >{form.do_the_body_stop_working_after_death.label}</label>
            </Grid>
            <Grid item xs={12}>
              <Confirm onChange={s => {
                if (s)
                  form.do_the_body_stop_working_after_death.setValue(CONFIRM.YES)
                else
                  form.do_the_body_stop_working_after_death.setValue(CONFIRM.NO)
              }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item xs={1}>
            <img src={enfermos} alt="" height="75%" width="75%" />
          </Grid>
          <Grid item xs={11} container>
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <Code>{form.what_happen_someone_can_die.key}</Code>
                <label htmlFor={form.what_happen_someone_can_die.key}>{form.what_happen_someone_can_die.label}</label>
              </Grid>
              <Grid item xs={12}>
                <textarea name={form.what_happen_someone_can_die.key}
                  onChange={e => setForm({ ...form, what_happen_someone_can_die: form.what_happen_someone_can_die.setValue(e.target.value) })}
                ></textarea>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Code>{form.why_someone_can_die.key}</Code>
              <label htmlFor={form.why_someone_can_die.key}>{form.why_someone_can_die.label}</label>
              <textarea name={form.why_someone_can_die.key}
                onChange={e => setForm({ ...form, why_someone_can_die: form.why_someone_can_die.setValue(e.target.value) })}
              ></textarea>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <Grid item container justifyContent="center">
        <Button disabled={!complete} onClick={handleSummit}>Continuar</Button>
      </Grid>
    </Grid>
  )
};

export default DeathAwareness;