import { Container, Grid } from '@mui/material';
import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Btn from '../../../components/Btn/Btn';
import Button from '../../../components/button/Button';
import { useFormContext } from '../../../formDataStore/formProvider';

import { getComponents } from '../../LayoutProcesor';
import { FormConfig, TGlobals } from '../../types';

// Stepper
export type TStep = {
  title?: string
  content: FormConfig[]
}

const Step: React.FC<TStep> = (props) => {
  const tittle = (props.title !== undefined) ? <>{props.title}</> : <></>;
  // const dataStore = React.useState({});
  return (
    <>
      {tittle}
      {getComponents(props.content, undefined,)}
    </>
  )
}

export type TStepper = {
  type: 'stepper',
  config: {
    onFinish: {
      save: boolean,
      confirmation_layout: FormConfig
      restart: boolean
    }
  }
  value: TStep[]
}

const Stepper: React.FC<{ globals: TGlobals } & TStepper> = (props) => {
  const { value: steps, config } = props;

  const [index, setIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState<JSX.Element | null>(null);

  const formContext = useFormContext();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [index])

  const onFinish = () => {
    window.scrollTo(0, 0);

    if (formContext.state.current_record)
      formContext.handlers.saveRecord(formContext.state.current_record.record_id);

    if (config.onFinish.confirmation_layout)
      setCurrentPage(getComponents(config.onFinish.confirmation_layout))
  }

  const handleGoToNextStep = () => {
    if (currentPage !== null) {
      setCurrentPage(null)
      setIndex(0);
      return;
    }

    if (index === steps.length - 1) {
      onFinish();
      setIndex(0);
    }
    else {
      setIndex(s => s + 1)
    }

  }

  const handleGoBack = () => {
    if (index === 0) return;
    setIndex(i => i - 1)
  }

  return (
    <SwitchTransition>
      <CSSTransition
        key={index.toString()}
        timeout={{
          appear: 300,
          enter: 300,
          exit: 300,
        }}
        classNames="step"
      >
        <div>
          {
            currentPage || <Step {...steps[index]} />
          }

          <Container maxWidth="md">
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item>
                {(index === 0 || currentPage) ? null : <Button onClick={handleGoBack} >Regresar</Button>}
              </Grid>
              <Grid item>
                <Button onClick={handleGoToNextStep} >Continuar</Button>
              </Grid>
            </Grid>
          </Container>
          {/* </Overlay> */}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

export default Stepper;