// context/todoContext.tsx
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { gePutUrl } from '../Config';

type TField = Record<string, any>

type TRecord = {
    form_code: string,
    record_id: string,
    fields: TField
};

type TState = {
    current_record?: TRecord;
    records: TRecord[];
};

type Action =
    | { type: 'RECORDS_ADD_NEW', payload: { form_code: string, record_id: string } }
    | { type: 'RECORDS_REMOVE', payload: { form_code: string, record_id: string } }

    | { type: 'UPDATE_FIELD_VALUE', payload: { form_code: string, record_id: string, field: TField } }
    | { type: 'CREATE_FIELD', payload: { form_code: string, record_id: string, field: TField } }

const FormContext = React.createContext<{
    state: TState,
    dispatch: React.Dispatch<Action>,
    handlers: {
        saveRecord: (recordId: string) => void
    }
} | null>(null);

export const useFormContext = () => {
    const context = React.useContext(FormContext)
    if (context === undefined || context === null) {
        throw new Error("useUserContextState was used outside of its Provider");
    }
    return context
};

const initialState: TState = {
    records: []
};

export const initializer = (code: string) => (initialValue = initialState): TState => {
    var localRecordsData = localStorage.getItem(code);

    if (localRecordsData == null) return initialValue;

    var localData = JSON.parse(localRecordsData);
    var dataIsAnArray = Object.prototype.toString.call(localData) === '[object Array]';

    // Fix for those records that are stored as a single object and not an Array
    var records: TRecord[] = (dataIsAnArray) ? localData as TRecord[] : [localData] as TRecord[];

    return {
        ...initialState,
        records
    }

}

export const recordsReducer = (state: TState, action: Action): TState => {


    switch (action.type) {
        case "RECORDS_ADD_NEW":
            return {
                ...state,
                records: [...state.records,
                { form_code: action.payload.form_code, record_id: action.payload.record_id, fields: {} }
                ]
            };
        case "RECORDS_REMOVE":
            return {
                ...state,
                records: [
                    ...state.records
                        .filter(r => r.form_code === action.payload.form_code && r.record_id === action.payload.record_id)
                ]
            }
    }

    const recordExists = (e: TRecord) => e.form_code === action.payload.form_code && e.record_id === action.payload.record_id;

    switch (action.type) {
        case "UPDATE_FIELD_VALUE":
            return {
                ...state,
                records: state.records.map(record => {
                    return {
                        ...record,
                        fields: { ...record.fields, ...action.payload.field }
                    }
                })
            }
    }

    return state;
};

const getValue = (value: string | string[]) => {
    if (Array.isArray(value)) return value.join();
    return value;
}

const FormProvider: React.FC<{
    children: React.ReactNode,
    formCode: string,
}> = ({ children, formCode }) => {

    const [currentRecordId, setCurrentRecordId] = React.useState<string | null>(null);

    const [formState, dispatch] = React.useReducer(recordsReducer, { records: [] }, initializer(formCode));

    React.useEffect(() => {
        if (currentRecordId) {
            var record = formState.records.find(r => r.form_code === formCode && r.record_id === currentRecordId);
            if (record === undefined)
                dispatch({ type: "RECORDS_ADD_NEW", payload: { form_code: formCode, record_id: currentRecordId } })
        } else {
            initNewRecord();
        }
    }, [currentRecordId]);

    const saveRecord = (recordId = currentRecordId) => {
        const record = formState.records.find(r => r.form_code === formCode && r.record_id === recordId);
        if (record === undefined) throw new Error("Tring to save an unexting record.");

        var form: Record<string, any> = { ...record.fields };

        for (const key in form) {
            if (Object.prototype.hasOwnProperty.call(form, key)) {
                if (Array.isArray(form[key]))
                    form[key] = form[key].join()
            }
        }

        var body = JSON.stringify({
            ...form
            // SUPERVISOR: searchParams.get("supervisor") || "",
            // latitude: coords.latitude,
            // longitude: coords.longitude
        });

        fetch(gePutUrl(formCode),
            // fetch('http://localhost:8080/fakeapi',
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: body
            })
            .then(r => {
                dispatch({ type: "RECORDS_REMOVE", payload: { form_code: formCode, record_id: record.record_id } })
            })// Delete Local Record
            .catch(e => {
                localStorage.setItem(formCode, JSON.stringify(formState.records))
            })
            .finally(() => {
                setCurrentRecordId(null)
                // let property: keyof typeof form;
                // for (property in form) {

                //     if (Object.prototype.hasOwnProperty.call(form, property)) {
                //         form[property].clean()
                //     }
                // }
                // fillPredefinedValues();
            });
    }

    const initNewRecord = () => {
        setCurrentRecordId(uuidv4())
    }

    var current_record = formState.records.find(r => r.form_code === formCode && r.record_id === currentRecordId)

    return (<FormContext.Provider value={{
        state: {
            current_record,
            records: formState.records
        },
        dispatch,
        handlers: { saveRecord }
    }}> {children}</FormContext.Provider >)
};

export default FormProvider;