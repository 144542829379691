import React from 'react';
import Grid from '@mui/material/Grid';

import logo from './../images/logo.png';

const Header = () => (
  <header>
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item xs={3} container alignItems='center'>
        <img
          src={logo}
          alt="Logo"
          onClick={() => {
            window.location.href = window.location.origin;
          }}
          style={{
            height: "auto",
            width: "100%",
            cursor: "pointer"
          }} />
      </Grid>
    </Grid>
  </header>
);

export default Header;