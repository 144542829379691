import React from 'react';

import styled from '@emotion/styled';
import './App.scss';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import Grid from '@mui/material/Grid';

import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Form from './pages/Form';
import AvisoDePrivacidad from './components/AvisoDePrivacidad';
import All from './All';

const Root = styled('div')(({ theme: _theme }) => ({
  flexGrow: 1,
  minHeight: "100vh",
  backgroundColor: '#d1e5ee',
}));

function App() {

  return (
    <Router>
      <Root>
        <Header />
        <Grid item>

          <Routes>
            <Route path={`/all`} element={<All />} />
            <Route path={`/aviso-de-privacidad`} element={< AvisoDePrivacidad />} />
            <Route path={`/:codigo`} element={<Form />} />
            <Route path={`/`} element={<Home />} />
          </Routes>

        </Grid>
        <Footer />
      </Root>
    </Router >
  );
}

export default App;
