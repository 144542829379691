import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { TFormElementParagraph } from '../../types';



const Paragraph: React.FC<TFormElementParagraph> = (props) => {
  const { value } = props;

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: value }} ></div>
    </>
  );
}

export default Paragraph;