
import { v4 as uuidv4 } from 'uuid';
import Input from '../components/Input/Input';

class Question<T> {

    key: string;
    label: string;
    required: boolean = true;
    initialValue: T;
    value: T;

    constructor(key: string, label: string, initValue: T, required: boolean = true) {
        this.key = key;
        this.label = label;
        this.initialValue = initValue;
        this.value = initValue;
        this.required = required;
    }

    validate(): boolean {
        if (this.required)
            if (this.value === null || this.value === undefined) return false;

        if (this.required)
            switch (typeof this.value) {
                case "string":
                    if (this.value === "")
                        return false;
                    break;
                default:
                    break;
            }

        return true;
    }

    setValue(newValue: T) {
        this.value = newValue;
        return this;
    }

    setRequired(newRequired: boolean) {
        this.required = newRequired;
        return this;
    }

    clean() {
        this.value = this.initialValue;
        return this;
    }

}
export default Question;