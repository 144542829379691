
import React from 'react';
import styles from './IconButton.module.css';


interface IProps {
    active?: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    children: React.ReactNode
}

const NextButton = ({ children, active, onClick }: IProps) => {


    return (
        <button
            className={`${styles.button} ${active ? styles.active : ''}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
}

export default NextButton;