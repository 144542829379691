import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import React from "react";

const getOption = (value: string, label: string, type?: string) => {
    switch (type) {
        case 'box':
            return <>TODO</>
        default:
            return <FormControlLabel value={value} control={<Radio />} label={label} />
    }
}

interface IProps {

}

const SingleOptionSelect = (props: { options: string[], onChange: (value: string) => void }) => {
    const [value, setValue] = React.useState<string | null>(null);

    return <Grid container justifyContent="space-evenly">
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            onChange={(e) => { props.onChange(e.target.value); setValue(e.target.value) }}
        >
            {
                props.options.map(o => (
                    <Grid item>
                        {getOption(o, o)}
                    </Grid>
                ))
            }
        </RadioGroup>
    </Grid >

}

export default SingleOptionSelect;